<template>

<div class="modal-overlay varificationModal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content d-flex justify-content-between" >
              <div class="modal-header d-flex justify-content-between align-items-center w-100">
    <h5 class="modal-title">
        <span >Compare Quotes</span>
    </h5>
    <button class="editform-cross border-0 ml-auto" @click="closemodal"><span style="font-size: 22px;">&times;</span></button>
</div>
        <div class="modal-body">
          
            <div class="d-flex justify-content-between">
                <!-- div1 -->
                <div class="align-items-center"  >
                 <div class="img-container">
                        <div>
                          
                                
                        </div>
                        
                  </div>
                  <div class="">
                    <p class="text-left pandetails1 " style="text-align: left; font-weight: 700;">Plan Name</p>
                  </div>
                  <div class="">
                    <p class="text-left pandetails" style="text-align: left; font-weight: 700;">Plan Id</p>
                  </div>
                  <div class="">
                    <p class="text-left pandetails" style="text-align: left; font-weight: 700;">Idv Cover</p>
                  </div>
                  <div class="">
                    <p class="text-left pandetails" style="text-align: left; font-weight: 700;">Total Premium</p>
                  </div>
                  <div>  <p class="text-left pandetails" style="text-align: left; font-weight: 700;">Addons</p></div>
                  <div>
                      <p v-for="item in uniqueAddons" style="text-align: left;" class="pandetails">{{ item }}</p>
                  </div>
                  <div class="">
                    <p class="text-left pandetails" style="text-align: left; font-weight: 700;">Cliam percentage</p>
                  </div>
                  <div class="">
                      <button style="visibility: hidden;">continue</button>
                  </div>
                </div>
                 <!-- div2 -->
                  
                <div class="align-items-center " v-for="(item,index) in compareList">
                    <div class="img-container">
                        <div>
                          <img
                                :src="resolveImagePath(item?.supportingData?.insCompanyLogo)"
                                alt="Bank Logo" class="compare-logo" draggable="false"
                                />
                                
                        </div>
                        
                  </div>
                <div>
                  <p class="compare-plan-para pandetails1">{{ item.productDetails.planOption.planName }}</p>
                </div>
                <div class="">
                  <p class="text-left pandetails" style="text-align: center ">{{ item.productDetails.planOption.planId }}</p>
                </div>
        
            <div class="">
              <p class="text-left pandetails" style="text-align: center">{{ item.supportingData.insuredDeclaredValue.suggestedIDV ? item.supportingData.insuredDeclaredValue.suggestedIDV: 'N/A' }}</p>
            </div>
            <div class="">
              <p class="text-left pandetails" style="text-align: center">{{ item.productDetails.totalPremiumDetails[0].totalPremiumWithTax  }}</p>
            </div>
            <div class="" >
                <div>  <p class="text-left pandetails" style="text-align: center;"><span style="visibility: hidden;">Addon</span></p></div>
                <div>
                    <p v-for="(item2) in uniqueAddons"  class="truefalse-sign pandetails">
                      <span v-if="item.productDetails.motorCovers.some((e)=>e.optionDescription==item2)"><img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/checkmark.png" alt="checkmark"/></span>
                      <span v-else><img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/delete-sign.png" alt="delete-sign"/></span>
                    </p>

                </div>
            </div>

            <div class="">
              <p class="text-left pandetails" style="text-align: center;">{{ item.supportingData.Messages.claimSettlementPercent["2021-2022"] ? item.supportingData.Messages.claimSettlementPercent["2021-2022"] :N/A}}</p>
            </div>
            <div class="text-center">
                <button @click="getSQ(item)" style="" class="p-1 continue-button">
                  Continue
                </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { options } from 'alasql';

export default{
    props:["compareList"],
    data(){
        return{
            uniqueAddons:[]
        }
        
    },
    mounted(){
     console.log("compareList",this.compareList)
     this.findUniqueAddons();
    },
    methods: {
    closemodal(){
  this.$emit('closeCompareQuote')
    },
    getSQ(data){
        this.$emit('closeCompareQuote')
        this.$emit('getPolicydetails',data)
    },
    findUniqueAddons(){
        this.compareList.forEach((ele) => {
         for (let i = 0; i < ele.productDetails.motorCovers?.length; i++) {
          // console.log("motercovers:",ele.productDetails.motorCovers[i])
         // Check if optionSelected exists and is not already in uniqueAddons
        if (ele.productDetails.motorCovers[i].optionDescription && !this.uniqueAddons.includes(ele.productDetails.motorCovers[i].optionDescription)) {
            this.uniqueAddons.push(ele.productDetails.motorCovers[i].optionDescription);
        }
    }
    console.log("uniqueAddons",this.uniqueAddons)
               
              })
        
            },
            
    resolveImagePath(variable) {
      return variable || "";
    },
}
}
</script>

<style lang="scss" scoped >

@import "../assets/scss/distributorVariables/variable.scss";
@import "../assets/scss/distributorVariables/_common.scss";

.img-container{
    width: 100px;
    height: 60px;
        display: flex;
    align-items: end;
    padding: 5px;
}
.varificationModal{
  .editform{
    font-size: 12px;
    color: $main-bg;
    display: flex;
    gap: 4px;
    align-items: center;
    border: none;
    background: transparent;
    img{
      width: 10px;
      height: 10px;
      stroke: $main-bg;
      fill: $main-bg;
    }
  }
  .editform-cross{
    position: absolute;
    right: 0;
    top: 0;
    color: blue;
  }
    .modal-content{
        width: fit-content;
        margin: 0px;
        border-radius: 16px;
        min-width: 570px;
        .modal-header{
            border: none;
    padding: 10px 10px 0;
    .modal-title{
        font-size: 16px;
        color: $text-color;
        
    }
        }
        
        p{
            font-size: 12px;
            color: $text-color;
            opacity: 0.5;
            text-align: center;
            margin: 0;
        }
        h6{
          margin: 0;
          font-size: 12px;
        }
    }
   
.otp-input-container{
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;

}
    .otp-input {
        width: 20px;
    height: 20px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border: navajowhite;
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
      }
      /* Background colour of an input field with value */
      .otp-input.is-complete {
        background-color: #e4e4e4;
      }
      .otp-input::-webkit-inner-spin-button,
      .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input::placeholder {
        font-size: 15px;
        text-align: left;
        font-weight: 600;
      }
      .commonButton, .btn:disabled {
        display: block;
        background: $main-bg;
        text-align: center;
        width: 100%;
        color: white;
        font-weight: 100;
        font-size: 13px;
        padding: 10px;
        border: 1px solid $main-bg;
        &:hover{
          background:transparent;
          border: 1px solid $main-bg;
          color: $main-bg;
        }
      }
      .backbutton{
        font-size: 12px;
        color:$main-bg;
        border-radius: 10px;
        padding: 4px 10px;
        border: 1px solid transparent;
        background: $commonColor;
        width: 100%;
        padding: 10px;
        font-weight: 100;
        font-size: 13px;
        padding: 10px;
        display: block;
      }
}


.breakupmodal{
  p{
    font-size: 12px;
    color: $text-color;
    margin: 0;
  }
  h6{
    font-size: 12px;
    font-weight: 600;
    color: $text-color;
  }
  h2{
    font-size: 16px;
    color: $text-color;
    margin: 0;
  }
}
.otpheading{
  font-size: 14px;
  color: $text-color;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup {
    position: relative;
    background-color: #FFF;
    max-width: 610px;
    min-height: 500px;
    max-height: fit-content;
    overflow-y: auto;
    // width: 90%;
    border-radius: 12px;
    padding: 48px 12px 12px;
    top:10px
}

#endclose {
    position: relative;
    left: 0;
}
.dark .modal-dialog-breakup{
    background: $header-dark-bg !important;
    *{
        color: $heading-darkColor !important;
    }
    .close_icon{
        filter: invert(1);
    }
}
.compare-logo{
  width: 100%;
  object-fit: contain;
  display: flex;
  align-items: end;
}
.compare-plan-para{
  font-size: 11px !important;
  opacity: 1 !important;
  
}
.truefalse-sign{
  opacity: 1 !important;
}
.pandetails{
     min-height: 40px !important;
    border-bottom: 2px solid #c3c3c3;
    display: inline-grid;
    align-items: center;
    width: 100%;
    opacity: 1 !important;
    padding: 5px;
}
.pandetails1{
     min-height: 53px !important;
    border-bottom: 2px solid #c3c3c3;
    display: inline-grid;
    align-items: center;
    width: 100%;
    opacity: 1 !important;
    padding: 5px;
}
.continue-button{
  background: blue;
    color: white;
    font-size: 12px;
    margin: auto;
    text-align: center;
    border-radius: 4px;
    border: 0;
    padding: 6px 11px !important;
    margin-top: 1rem;
}
</style>