<template lang="">
  <ProductDetailsHeader/>
    <div class="thank-you">
      <div class="text-center">
        <img src="/images/paymentFailed.gif" class="m-auto" />
        <h4 class=" text-center  text-danger">Payment Failed</h4>
        <p class="text-center">Please Retry</p>
        
        <router-link 
  :to="{ 
    path: '/confirm-payment-diy', 
    query: { journeyId: Journey_id } 
  }" 
  class="text-center mt-5">
  Retry Payment
</router-link>
       
      
        
      </div>
    </div> 
  </template>
  <script>

import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';

  export default {
  components:{
    ProductDetailsHeader
  },
  data(){
       return{
        Journey_id:''
       }
  },
  mounted(){
    this.Journey_id=JSON.parse(sessionStorage.getItem("journey_id"));
  },
    
  
  
  };
  </script>
  <style lang="scss">
 @import "../../assets/scss/components/Loaders.scss";
  </style>
  