<template lang="">
    <div>
        <innerHeaderDiy/>
        <PaymentFailed/>
    </div>
</template>
<script>
import PaymentFailed from '@/components/pageLoaders/paymentFailed.vue';
import innerHeaderDiy from "@/components/header/innerHeaderDiy";


export default {
    components:{
        PaymentFailed,
        innerHeaderDiy
    }
}
</script>
<style lang="">
    
</style>