<template>
  <!-- <div class=""> -->
  <ProductDetailsHeader></ProductDetailsHeader>
      <div class="wrapper" >
        <div class="inner-wrapper">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8 col-md-8 col-xl-8">
              <div class="formWizard row proccess-wizard " style="margin-bottom:7rem" >
                <div class="wizard-hold" >
                  <div class="row">
                    <div class="col-md-6">
                      <ul class="nav nav-tabs wizard-step" id="myTab" role="tablist" >
                        <template v-for="(data, ind) in proposalJson.fieldGroups" :key="ind">
                          <template v-if="ind !='traceInfo' && ind != 'distributor'">
                            <li class="nav-item" role="presentation" v-if="!data.visibility ? true : checkVisibility(data.visibility)">
                              <button :class="'nav-link ' + activePage(ind)" :id="'step' + ind + '-tab'" data-bs-toggle="tab"
                                :data-bs-target="'#step' + ind" type="button" role="tab" :aria-controls="'step' + ind"
                                aria-selected="true">
                                <div class="d-flex gap-2 align-items-center">
                                  <div class="cir">
                                    <img src="../../assets/images/usericon.svg" class="usersvg"  />
                                  
                                  <span class="bi bi-check"></span>
                                </div>
                                <span class="txt">{{
                                  checkArray(data) ? data[0].label : data.label
                                }}</span>
                                </div>
                               
                              </button>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <div class="tab-content" id="myTabContent">
                    <div :class="'tab-pane fade ' + isTabContentActive(ind)" v-for="(data, ind) in proposalJson.fieldGroups"
                      :key="ind" :id="'step' + ind" role="tabpanel" :aria-labelledby="'step' + ind">
                      <div class="tab-body" v-if="ind !='traceInfo' && ind != 'distributor'">
                        <!--Proposer detail form step1-->
                        <div class="floating-form card application-section">
                          <h4 class="title_subtitle text-start">
                            <!-- Let's start with -->
                            {{
                              checkArray(data)
                              ? data[0].description
                              : data.description
                            }}
                          </h4>
                          <!-- <span class="base-reg">{{ data.description }}</span> -->
                          <div class="form-wrapper pt-3">
                            <form @submit.prevent="submitForm(ind)" method="post">
                              <!-- <h5 class="mb-3">{{ data.label }}</h5> -->
                              <div v-if="checkArray(data)">
                                <div class="row" v-for="(value2, ind2) in data" :key="ind2">
                                  <!-- <h5 class="members-wrapper">
                                    {{ value2.label }}
                                    <span v-if="value2.relation">&nbsp;{{ value2?.relation }} Details </span>
                                  </h5> -->
                                  <!-- if fields are available -->
                                  <template v-for="(value3, ind3) in value2.fields" :key="ind3"> 
                                    <div :class="getColSize(value3.type, ind, ind2)" v-if="!value3.visibility ? true : checkVisibility(value3.visibility)">
                                      <div v-if="value3.type == 'text'">
                                        <!-- Text Field -->
                                        <div class="floating-label">
                                          <input class="floating-input" type="text" :ph="value3.label" :name="value3.name" :placeholder="value3.label"
                                            :id="value3.id" :min="value3.min" :max="value3.max"
                                            :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" 
                                            v-model.trim="formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                            @keyup="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                          />
                                          
                                          <span class="error-msg" :id="'error_' + value3.id"></span>
                                        </div>
                                      </div>
                                      <!-- Number Field -->
                                      <div v-if="value3.type == 'number'" :class="getColSize(value3.type, ind, ind2)">
                                        <label>
                                            {{ value3.label }}
                                            <text v-if="
                                              value3.mandatory == '1' ||
                                              value3.mandatory == '2'
                                            " class="mandatory-mark">*</text>
                                          </label>
                                        <div class="floating-label">
                                          <input class="floating-input" type="number" :ph="value3.label" :name="value3.name" :placeholder="value3.label"
                                            :id="value3.id" :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" v-model.trim="
                                            formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                            :title="getDescriptionText(value3.description)" 
                                            @keyup="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                          />
                                          
                                          <span class="error-msg" :id="'error_' + value3.id">{{ value3.label }}</span>
                                        </div>
                                      </div>
                                      <!-- Date Field -->
                                      <div v-if="value3.type == 'date'" :class="getColSize(value3.type, ind, ind2)">
                                        <label>
                                            {{ value3.label }}
                                            <text v-if="
                                              value3.mandatory == '1' ||
                                              value3.mandatory == '2'
                                            " class="mandatory-mark">*</text>
                                          </label>
                                        <div class="floating-label">
                                          <input class="floating-input" type="date" :ph="value3.label" :name="value3.name" :placeholder="value3.label"
                                            :id="value3.id" :min="value3.min" :max="value3.max"
                                            :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" 
                                            v-model.trim="formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                            @keyup="getValidation($event, value3.name, value3.id, ind, ind2, ind3) + validateYear(ind, formInfo.fieldGroups[ind][ind2].fields[ind3].input)"
                                          />
                                         
                                          <span class="error-msg" :id="'error_' + value3.id"></span>
                                        </div>
                                      </div>
                                      <!-- Single Select Field -->
                                      <div v-if="value3.type == 'single-select'" :class="getColSize(value3.type, ind, ind2)">
                                        <div class="floating-label">
                                          <label>
                                            {{ value3.label }}
                                            <text v-if="
                                              value3.mandatory == '1' ||
                                              value3.mandatory == '2'
                                            " class="mandatory-mark">*</text>
                                          </label>
                                          <select class="floating-select format" :name="value3.name" :id="value3.id"
                                            :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                              :title="getDescriptionText(value3.description)"
                                              @change="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                            >
                                            <option value="" :disabled="value3.mandatory == 2 || value3.mandatory == 3">Select</option>
                                            <option :value="item.Value" v-for="(item, ind3) in sortDropDown(
                                              value3.value
                                            )" :key="ind3">
                                              {{ item.Text }}
                                            </option>
                                          </select>
                                          
                                          <span class="error-msg" :id="'error_' + value3.id"></span>
                                        </div>
                                      </div>
                                      <!-- Multi Select Field -->
                                      <div v-if="value3.type == 'multi-select'" :class="getColSize(value3.type, ind, ind2)">
                                        <label>
                                            {{ value3.label }}
                                            <text v-if="
                                              value3.mandatory == '1' ||
                                              value3.mandatory == '2'
                                            " class="mandatory-mark">*</text>
                                          </label>
                                        <div class="floating-label">
                                          <select class="floating-select format" :name="value3.name" :id="value3.id"
                                            :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                              :title="getDescriptionText(value3.description)"
                                              @change="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                            >
                                            <option value="" :disabled="value3.mandatory == 2 || value3.mandatory == 3">Select</option>
                                            <option :value="item.Value" v-for="(item, ind3) in sortDropDown(
                                              value3.value
                                            )" :key="ind3">
                                              {{ item.Text }}
                                            </option>
                                          </select>
                                          
                                          <span class="error-msg" :id="'error_' + value3.id"></span>
                                        </div>
                                      </div>
                                      <!-- Static Field -->
                                      <div v-if="value3.type == 'static'">
                                        <div class="reverse-col mb-3">
                                          <label for="email" class="form-item-label">
                                            {{
                                                value3.label
                                            }}
                                          </label>
                                        </div>
                                      </div>
                                      <!-- Radio Button Field -->
                                      <div v-if="value3.type == 'boolean'" :class="getColSize(value3.type, ind, ind2)"
                                        :id="value3.id">
                                        <div class="d-flex align-items-center mb-3">
                                          <h5 class="label_radio_align">
                                            {{ value3.label }}
                                            <text v-if="
                                              value3.mandatory == '1' ||
                                              value3.mandatory == '2'
                                            " class="mandatory-mark">*</text>
                                          </h5>
                                          <div class="radio-btn-grp d-flex gap-4">
                                            <div class="radio-btn-wrap">
                                              <input type="radio" :id="value3.id + '1'" :name="value3.level" v-model.trim="
                                                formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                                v-bind:value="'1'" :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" 
                                                @click="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                              />
                                              <label :for="value3.id + '1'" class="radio-label">Yes</label>
                                            </div>
                                            <div class="radio-btn-wrap">
                                              <input type="radio" :id="value3.id + '2'" name="value3.level" v-model.trim="
                                                formInfo.fieldGroups[ind][ind2].fields[ind3].input" 
                                                v-bind:value="'0'" :disabled="value2?.relation =='Self' && (value3.mandatory == 2 || value3.mandatory == 3)" 
                                                @click="getValidation($event, value3.name, value3.id, ind, ind2, ind3)"
                                              />
                                              <label :for="value3.id + '2'" class="radio-label">No</label>
                                            </div>
                                          </div>
                                        </div>
                                        <span class="error-msg" :id="'error_' + value3.id"></span>
                                      </div>
                                    </div>
                                  </template>
                                  <!-- if fieldGroups are available -->
                                  <template v-for="(value3, ind3) in value2.fieldGroups" :key="ind3">
                                    <!-- <h5 class="mb-3 pt-2">{{ value3.label }}</h5> -->
                                    <h5 class="mb-3">{{ value2?.relation }} Details</h5>
                                    <template v-for="(value4, ind4) in value3.fields" :key="ind4">
                                      <div :class="getColSize(value4.type, ind, ind2, ind3)" v-if="!value4.visibility ? true : checkVisibility(value4.visibility)">
                                        <div v-if="value4.type == 'text'">
                                          <!-- Text Field -->
                                          <div class="floating-label">
                                            <label>{{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="text" :ph="value4.label" :name="value4.name" :placeholder="value4.label"
                                              :id="value4.id + ind2" :min="value4.min" :max="value4.max"
                                              :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)" 
                                              v-model.trim="formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input" 
                                              @keyup="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                            />
                                           
                                            <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                          </div>
                                        </div>
                                        <!-- Number Field -->
                                        <div v-if="value4.type == 'number'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="number" :ph="value4.label" :name="value4.name" :placeholder="value4.label"
                                              :id="value4.id + ind2" :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input"
                                              :title="getDescriptionText(value4.description)"
                                              @keyup="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                            />
                                            
                                            <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                          </div>
                                        </div>
                                        <!-- Date Field -->
                                        <div v-if="value4.type == 'date'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="date" :ph="value4.label" :name="value4.name" :placeholder="value4.label"
                                              :id="value4.id + ind2" :min="value4.min" :max="value4.max"
                                              :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input"
                                              @keyup="getValidation($event, value4.name, value4.id, ind, ind2, ind3) + validateYear(ind, formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input)"
                                              />
                                            
                                            <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                          </div>
                                        </div>
                                        <!-- Single Select Field -->
                                        <div v-if="value4.type == 'single-select'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <select class="floating-select format" :name="value4.name" :id="value4.id + ind2"
                                              :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input"
                                              :title="getDescriptionText(value4.description)"
                                              @change="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                            >
                                              <option value="" :disabled="value4.id=='2524' || value4.id=='2533'">Select</option>
                                              <option :value="item.Value" v-for="(item, ind5) in sortDropDown(
                                                value4.value
                                              )" :key="ind5" :disabled="value4.id=='2524' || value4.id=='2533'">
                                                {{ item.Text }}
                                              </option>
                                            </select>
                                           
                                            <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                          </div>
                                        </div>
                                        <!-- Multi Select Field -->
                                        <div v-if="value4.type == 'multi-select'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <select class="floating-select format" :name="value4.name" :id="value4.id + ind2"
                                              :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)" v-model.trim="
                                              formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input"
                                              :title="getDescriptionText(value4.description)"
                                              @change="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                            >
                                            <option value="" :disabled="value4.mandatory == 2 || value4.mandatory == 3">Select</option>
                                              <option :value="item.Value" v-for="(item, ind5) in sortDropDown(
                                                value4.value
                                              )" :key="ind5">
                                                {{ item.Text }}
                                              </option>
                                            </select>
                                            
                                            <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                          </div>
                                        </div>
                                        <!-- Static Field -->
                                        <div v-if="value4.type == 'static'">
                                          <div class="reverse-col mb-3">
                                            <br/>
                                          </div>
                                        </div>
                                        <!-- Radio Button Field -->
                                        <div v-if="value4.type == 'boolean'" :id="value4.id">
                                          <div class="d-flex align-items-center mb-3">
                                            <h5 class="label_radio_align">
                                              {{ value4.label }}
                                              <text v-if="
                                                value4.mandatory == '1' ||
                                                value4.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </h5>
                                            <div class="radio-btn-grp d-flex gap-4">
                                              <div class="radio-btn-wrap">
                                                <input type="radio" :id="value4.id + '1' + ind2" :name="value4.level" v-model.trim="
                                                  formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input" 
                                                  v-bind:value="'1'" :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)"
                                                  @click="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                                />
                                                <label :for="value4.id + '1' + ind2" class="radio-label">Yes</label>
                                              </div>
                                              <div class="radio-btn-wrap">
                                                <input type="radio" :id="value4.id + '2' + ind2" :name="value4.level" v-model.trim="
                                                  formInfo.fieldGroups[ind][ind2]['fieldGroups'][ind3].fields[ind4].input" 
                                                  v-bind:value="'0'" :disabled="value2?.relation =='Self' && (value4.mandatory == 2 || value4.mandatory == 3)"
                                                  @click="getValidation($event, value4.name, value4.id, ind, ind2, ind3)"
                                                />
                                                <label :for="value4.id + '2' + ind2" class="radio-label">No</label>
                                              </div>
                                            </div>
                                          </div>
                                          <span class="error-msg" :id="'error_' + value4.id + ind2"></span>
                                        </div>
                                      </div>
                                    </template>
                                  </template>
                                </div>
                              </div>
                              <div v-else>
                                <div class="health-questions" v-if="ind == 'healthQuestions'">
  
                                  <span class="mid-heading mt-3">
                                    Medical Questions for
                                    <template v-for="(insMember, insInd) in this.formInfo?.fieldGroups?.insuredMembers">
                                      {{getTrim(insMember.fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input)}}
                                      {{getTrim(insMember.fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input)}}
                                      {{getTrim(insMember.fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input)}}{{(this.formInfo?.fieldGroups?.insuredMembers[insInd+1])?', ':''}}
                                    </template>
                                  </span>
  
                                  <!-- if fields are available -->
                                  <div class="row question-wrapper" v-if="data.fields">
                                    <template v-for="(value2, ind2) in data.fields" :key="ind2">
                                      <h4 :class="(value2.visibility)?' sub-questions':' main-questions'" v-if="
                                        !value2.visibility
                                          ? true
                                          : checkVisibility(
                                            getVisitibilityExpIndexZero(
                                              value2.visibility
                                            )
                                          )
                                      ">
                                        <div class="sub-question-wrapper">
                                        <label>
                                          <span v-if="!value2.visibility">Q.</span> {{ value2.label }}
                                          <text v-if="
                                            value2.mandatory == '1' ||
                                            value2.mandatory == '2'
                                          " class="mandatory-mark">*</text>
                                        </label>
                                        </div>
                                      </h4>
                                      <template v-if="value2.type != 'static'">
  
                                        <template v-if="value2.type == 'boolean' && !value2.visibility">
                                          <h5 class="none-option" :class="getNoneOfMembersClass(value2.id, ind, ind2)">
                                            <div class="custom-checkbox">
                                              <input type="checkbox" :id="value2.id" v-on:click="setNoneOfFamilyMember(value2.id, ind, ind2)" :checked="getVisibleClass(value2.id, ind, ind2)">
                                              <label :for="value2.id">None of the family members </label>
                                            </div>
                                          </h5>
                                        </template>
  
                                        <!--  jjjkkkkkkkkk --> 
                                        <!-- + (getVisibleClass(value2.id, ind, ind2)?' d-none ':'') -->
                                        <!-- <template :class="value2.id+'_section '" v-for="(value3, ind3) in selectedMembersList" :key="ind3">  -->
                                        <div :id="value2.id+'_section'" :class="((value2.visibility)?' sub-questions ':' main-questions ')">
                                          <template v-for="(value3, ind3) in this.formInfo?.fieldGroups?.insuredMembers.length" :key="ind3">
                                            <!-- <span v-if="value2.visibility">{{ getVisitibilityExp(value2.visibility, ind3) }}</span> -->
                                            <div :class="getColSize(value2.type, ind, ind2)" v-if="!value2.visibility? true: checkVisibility( getVisitibilityExp(value2.visibility, ind3))">
                                              <div v-if="value2.type == 'text'">
                                                <div class="floating-label">
                                                  <label>
                                                    
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }}
                                                    <text v-if="
                                                      value2.mandatory == '1' ||
                                                      value2.mandatory == '2'
                                                    " class="mandatory-mark"></text>
                                                  </label>
                                                  <input class="floating-input" type="text" :ph="value2.label" :placeholder="value2.label"
                                                    :name="value2.name" :id="value2.id" :min="value2.min"
                                                    :max="value2.max" :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                                    v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                    @keyup="getValidation($event, value2.name, value2.id, ind, ind2, ind3)"
                                                  />
                                                  
                                                  <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                                </div>
                                              </div>
                                              <!-- Number Field -->
                                              <div v-if="value2.type == 'number'">
                                                <div class="floating-label">
                                                  <label>
                                                   
                                                   {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }}
                                                   {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }}
                                                   {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }}
                                                   <text v-if="
                                                     value2.mandatory == '1' ||
                                                     value2.mandatory == '2'
                                                   " class="mandatory-mark"></text>
                                                 </label>
                                                  <input class="floating-input" type="number" :ph="value2.label" :placeholder="value4.label"
                                                    :name="value2.name" :id="value2.id" :disabled="value2.mandatory == 2 || value2.mandatory == 3" 
                                                    v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                    :title="getDescriptionText(value2.description)"
                                                    @keyup="getValidation($event, value2.name, value2.id, ind, ind2, ind3)"
                                                  />
                                                  
                                                  <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                                </div>
                                              </div>
                                              <!-- Date Field -->
                                              <div v-if="value2.type == 'date'">
                                                <div class="floating-label">
                                                  <label>
                                                    
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }}
                                                    <text v-if="
                                                      value2.mandatory == '1' ||
                                                      value2.mandatory == '2'
                                                    " class="mandatory-mark"></text>
                                                  </label>
                                                  <input class="floating-input" type="date" :ph="value2.label" :placeholder="value4.label"
                                                    :name="value2.name" :id="value2.id" :min="value2.min"
                                                    :max="value2.max" :disabled="value2.mandatory == 2 || value2.mandatory == 3"  v-model.trim="
                                                    formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                    @keyup="getValidation($event, value2.name, value2.id, ind, ind2, ind3) + validateYear(ind, formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer)"
                                                  />
                                                  
                                                  <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                                </div>
                                              </div>
                                              <!-- Single Select Field -->
                                              <div v-if="value2.type == 'single-select'">
                                                <div class="floating-label">
                                                  <label>
                                                    <!-- {{ value3.member }} -->
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }}
                                                    <text v-if="
                                                      value2.mandatory == '1' ||
                                                      value2.mandatory == '2'
                                                    " class="mandatory-mark"></text>
                                                  </label>
                                                  <select class="floating-select format" :name="value2.name" :id="value2.id"
                                                    :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                                    v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                    :title="getDescriptionText(value2.description)"
                                                    @change="getValidation($event, value2.name, value2.id, ind, ind2, ind3)"
                                                  >
                                                  <option value="" :disabled="value2.mandatory == 2 || value2.mandatory == 3" >Select</option>
                                                    <option :value="item.Value" v-for="(item, ind3) in sortDropDown(value2.value)" :key="ind3">
                                                      {{ item.Text }}
                                                    </option>
                                                  </select>
                                                 
                                                  <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                                </div>
                                              </div>
                                              <!-- Multi Select Field -->
                                              <div v-if="value2.type == 'multi-select'">
                                                <div class="floating-label">
                                                  <label>
                                                    <!-- {{ value3.member }} -->
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }}
                                                    {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }}
                                                    <text v-if="
                                                      value2.mandatory == '1' ||
                                                      value2.mandatory == '2'
                                                    " class="mandatory-mark"></text>
                                                  </label>
                                                  <select class="floating-select format" :name="value2.name" :id="value2.id"
                                                    :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                                    v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                    :title="getDescriptionText(value2.description)"
                                                    @change="getValidation($event, value2.name, value2.id, ind, ind2, ind3)"
                                                  >
                                                  <option value="" :disabled="value2.mandatory == 2 || value2.mandatory == 3" >Select</option>
                                                    <option :value="item.Value" v-for="(item, ind3) in sortDropDown(value2.value)" :key="ind3">
                                                      {{ item.Text }}
                                                    </option>
                                                  </select>
                                                 
                                                  <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                                </div>
                                              </div>
                                              <!-- Static Field -->
                                              <div v-if="value2.type == 'static'">
                                                <div class="reverse-col mb-3">
                                                  <br/>
                                                </div>
                                              </div>
                                              <!-- Radio Button Field -->
                                              <div v-if="value2.type == 'boolean'" :id="value2.id" class="member mb-1">
                                                <div class="d-flex align-items-center">
                                                  
                                                  <h5 class="label_radio_align">
                                                    <label>
                                                      <!-- <input type="Checkbox" :id="value2.id + '1' + ind3" v-on:click="setCheckboxValue(value2.id + '1' + ind3, ind, ind2, ind3)" /> -->
                                                      {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.firstName?.input }} 
                                                      {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.middleName?.input }} 
                                                      {{ this.formInfo?.fieldGroups?.insuredMembers[ind3].fieldGroups?.insuredMemberPersonalDetails?.fields?.lastName?.input }} 
                                                      <!-- {{ value3.member }} -->
                                                      <text v-if="
                                                        value2.mandatory == '1' ||
                                                        value2.mandatory == '2'
                                                      " class="mandatory-mark"></text>
                                                    </label>
                                                  </h5>
                                                
                                                  <div class="radio-btn-grp switch-btn d-flex gap-4">
                                                    <div class="radio-btn-wrap">
                                                      <input type="radio" :id="value2.id + '1' + ind3" :name="
                                                        value2.name + '_' + ind3" 
                                                        v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                        v-bind:value="'1'" 
                                                        :disabled="value2.mandatory == 2 || value2.mandatory == 3" 
                                                        @click="getValidation($event, value2.name, value2.id, ind, ind2, ind3)" 
                                                      />
                                                      <label :for="value2.id + '1' + ind3" class="radio-label">Yes</label>
                                                    </div>
                                                    <div class="radio-btn-wrap">
                                                      <input type="radio" :id="value2.id + '2' + ind3" :name="
                                                        value2.name + '_' + ind3" 
                                                        v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input[ind3].answer" 
                                                        v-bind:value="'0'" 
                                                        :disabled="value2.mandatory == 2 || value2.mandatory == 3" 
                                                        @click="getValidation($event, value2.name, value2.id, ind, ind2, ind3)"
                                                      />
                                                      <label :for="value2.id + '2' + ind3" class="radio-label">No</label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <span class="error-msg" :id="'error_' + value2.id + '_' + ind3"></span>
                                              </div>
                                            </div>
                                          </template>
                                        </div>
                                        
                                      </template>
                                    </template>
                                  </div>
                                </div>
                                <div v-else class="health-questions-else">
                                  <!-- if fields are available -->
                                  <div class="row" v-if="data.fields">
                                    <template v-for="(value2, ind2) in data.fields" :key="ind2">
                                      <div :class="getColSize(value2.type, ind, ind2)" v-if="!value2.visibility ? true : checkVisibility(value2.visibility)">
                                        <!-- Text Field -->
                                        <div v-if="value2.type == 'text'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="text" :ph="value2.label" :name="value2.name" :placeholder="value2.label"
                                              :id="value2.id" :min="value2.min" :max="value2.max"
                                              :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                              v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input" 
                                              @keyup="getValidation($event, value2.name, value2.id, ind, ind2)"
                                            />
                                            
                                            <span class="error-msg" :id="'error_' + value2.id"></span>
                                          </div>
                                        </div>
                                        <!-- Number Field -->
                                        <div v-if="value2.type == 'number'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="number" :ph="value2.label" :name="value2.name" :placeholder="value2.label"
                                              :id="value2.id" :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                              v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input" 
                                              :title="getDescriptionText(value2.description)" 
                                              @keyup="getValidation($event, value2.name, value2.id, ind, ind2)"
                                            />
                                            
                                            <span class="error-msg" :id="'error_' + value2.id"></span>
                                          </div>
                                        </div>
                                        <!-- Date Field -->
                                        <div v-if="value2.type == 'date'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <input class="floating-input" type="date" :ph="value2.label" :name="value2.name" :placeholder="value2.label"
                                              :id="value2.id" :min="value2.min" :max="value2.max"
                                              :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                              v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input" 
                                              @keyup="getValidation($event, value2.name, value2.id, ind, ind2) + validateYear(ind, formInfo.fieldGroups[ind].fields[ind2].input)"
                                            />
                                           
                                            <span class="error-msg" :id="'error_' + value2.id"></span>
                                          </div>
                                        </div>
                                        <!-- Single Select Field -->
                                        <div v-if="value2.type == 'single-select'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <select class="floating-select format" :name="value2.name" :id="value2.id"
                                              :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                              v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input" 
                                              :title="getDescriptionText(value2.description)"
                                              @change="getValidation($event, value2.name, value2.id, ind, ind2)"
                                            >
                                            <option value="" :disabled="value2.mandatory == 2 || value2.mandatory == 3" >Select</option>
                                              <option :value="item.Value" v-for="(item, ind3) in sortDropDown(
                                                value2.value
                                              )" :key="ind3">
                                                {{ item.Text }}
                                              </option>
                                            </select>
                                           
                                            <span class="error-msg" :id="'error_' + value2.id"></span>
                                          </div>
                                        </div>
                                        <!-- Multi Select Field -->
                                        <div v-if="value2.type == 'multi-select'">
                                          <div class="floating-label">
                                            <label>
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </label>
                                            <select class="floating-select format" :name="value2.name" :id="value2.id"
                                              :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                              v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input" 
                                              :title="getDescriptionText(value2.description)"
                                              @change="getValidation($event, value2.name, value2.id, ind, ind2)"
                                            >
                                            <option value="" :disabled="value2.mandatory == 2 || value2.mandatory == 3" >Select</option>
                                              <option :value="item.Value" v-for="(item, ind3) in sortDropDown(
                                                value2.value
                                              )" :key="ind3">
                                                {{ item.Text }}
                                              </option>
                                            </select>
                                           
                                            <span class="error-msg" :id="'error_' + value2.id"></span>
                                          </div>
                                        </div>
                                        <!-- Static Field -->
                                        <div v-if="value2.type == 'static'">
                                          <div class="reverse-col mb-3">
                                            <br/>
                                          </div>
                                        </div>
                                        <!-- Radio Button Field -->
                                        <div v-if="value2.type == 'boolean'" :id="value2.id">
                                          <div class="d-flex align-items-center mb-3">
                                            <h5 class="label_radio_align">
                                              {{ value2.label }}
                                              <text v-if="
                                                value2.mandatory == '1' ||
                                                value2.mandatory == '2'
                                              " class="mandatory-mark">*</text>
                                            </h5>
                                            <div class="radio-btn-grp d-flex gap-4">
                                              <div class="radio-btn-wrap">
                                                <input type="radio" :id="value2.id + '1'" :name="value2.name"
                                                  v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input"
                                                  v-bind:value="'1'" :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                                  @click="getValidation($event, value2.name, value2.id, ind, ind2)"
                                                />
                                                <label :for="value2.id + '1'" class="radio-label">Yes</label>
                                              </div>
                                              <div class="radio-btn-wrap">
                                                <input type="radio" :id="value2.id + '2'" :name="value2.name"
                                                  v-model.trim="formInfo.fieldGroups[ind].fields[ind2].input"
                                                  v-bind:value="'0'" :disabled="value2.mandatory == 2 || value2.mandatory == 3"  
                                                  @click="getValidation($event, value2.name, value2.id, ind, ind2)"
                                                />
                                                <label :for="value2.id + '2'" class="radio-label">No</label>
                                              </div>
                                            </div>
                                          </div>
                                          <span class="error-msg" :id="'error_' + value2.id"></span>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                  <!-- if fieldGroups are available -->
                                  <template v-for="(value2, ind2) in data.fieldGroups" :key="ind2">
                                    <div v-if="!value2.visibility? true: checkVisibility(value2.visibility)">
                                      <h5 class="mb-3">{{ value2.label }}</h5>
                                      <div class="row">
                                        <template v-for="(value3, ind3) in value2.fields" :key="ind3">
                                          <div :class="getColSize(value3.type, ind, ind2, ind3)" v-if="!value3.visibility ? true : checkVisibility(value3.visibility)">
                                            <!-- Text Field --> 
                                            <div v-if="value3.type == 'text'">
                                              <div class="floating-label">
                                                <label>
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </label>
                                                <input class="floating-input" type="text" :ph="value3.label" :name="value3.name" :placeholder="value3.label"
                                                  :id="value3.id" :min="value3.min" :max="value3.max"
                                                  :disabled="value3.mandatory == 2 || value3.mandatory == 3" 
                                                  v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                  :title="getDescriptionText(value3.description)" 
                                                  @keyup="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                />
                                                
                                                <span class="error-msg" :id="'error_' + value3.id"></span>
                                              </div>
                                            </div>
                                            <!-- Number Field -->
                                            <div v-if="value3.type == 'number'">
                                              <div class="floating-label">
                                                <label>
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </label>
                                                <input class="floating-input" type="number" :ph="value3.label" :placeholder="value3.label"
                                                  :name="value3.name" :id="value3.id" :disabled="value3.mandatory == 2 || value3.mandatory == 3"
                                                  v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                  :title="getDescriptionText(value3.description)" 
                                                  @keyup="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                />
                                                
                                                <span class="error-msg" :id="'error_' + value3.id"></span>
                                              </div>
                                            </div>
                                            <!-- Date Field -->
                                            <div v-if="value3.type == 'date'">
                                              <div class="floating-label">
                                                <label>
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </label>
                                                <input class="floating-input" type="date" :ph="value3.label" :name="value3.name" :placeholder="value3.label"
                                                  :id="value3.id" :min="value3.min" :max="value3.max"
                                                  :disabled="value3.mandatory == 2 || value3.mandatory == 3" 
                                                  v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                  @keyup="getValidation($event, value3.name, value3.id, ind, ind2) + validateYear(ind, formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input)"
                                                />
                                               
                                                <span class="error-msg" :id="'error_' + value3.id"></span>
                                              </div>
                                            </div>
                                            <!-- Single Select Field -->
                                             
                                            <div v-if="value3.type == 'single-select'">
                                              
                                              <div class="floating-label">
                                                <label class="selectlabel">
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </label>
                                                <select class="floating-select format" :name="value3.name" :id="value3.id"
                                                  :disabled="value3.mandatory == 2 || value3.mandatory == 3"
                                                  v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                  :title="getDescriptionText(value2.description)"
                                                  @change="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                >
                                                  <option value="" :disabled="value3.id=='2495'">Select</option>
                                                  <option :value="item.Value" v-for="(item, ind4) in sortDropDown(value3.value)" :key="ind3" :disabled="value3.id=='2495'" >
                                                    {{ item.Text }}
                                                  </option>
                                                </select>
                                                
                                                <span class="error-msg" :id="'error_' + value3.id"></span>
                                              </div>
                                            </div>
                                            <!-- Multi Select Field -->
                                            <div v-if="value3.type == 'multi-select'">
                                              <div class="floating-label">
                                                <label>
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </label>
                                                <select class="floating-select format" :name="value3.name" :id="value3.id"
                                                  :disabled="value3.mandatory == 2 || value3.mandatory == 3" 
                                                  v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                  :title="getDescriptionText(value2.description)"
                                                  @change="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                >
                                                <option value="" :disabled="value3.mandatory == 2 || value3.mandatory == 3">Select</option>
                                                  <option :value="item.Value" v-for="(item, ind4) in sortDropDown(value3.value)" :key="ind3">
                                                    {{ item.Text }}
                                                  </option>
                                                </select>
                                                
                                                <span class="error-msg" :id="'error_' + value3.id"></span>
                                              </div>
                                            </div>
                                            <!-- Static Field -->
                                            <div v-if="value3.type == 'static'">
                                              <div class="reverse-col mb-3">
                                                <label for="email" class="form-item-label">
                                                  {{
                                                      value3.label
                                                  }}
                                                </label><br/>
                                              </div>
                                            </div>
                                            <!-- Radio Button Field -->
                                            <div v-if="value3.type == 'boolean'" :id="value3.id">
                                              <div class=" mb-3">
                                                <h5 class="label_radio_align">
                                                  {{ value3.label }}
                                                  <text v-if="
                                                    value3.mandatory == '1' ||
                                                    value3.mandatory == '2'
                                                  " class="mandatory-mark">*</text>
                                                </h5>
                                                <div class="radio-btn-grp d-flex gap-4">
                                                  <div class="radio-btn-wrap">
                                                    <input type="radio" :id="value3.id + '1'" :name="value3.level" 
                                                      v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                      v-bind:value="'1'" 
                                                      :disabled="value3.mandatory == 2 || value3.mandatory == 3" 
                                                      @click="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                    />
                                                    <label :for="value3.id + '1'" class="radio-label">Yes</label>
                                                  </div>
                                                  <div class="radio-btn-wrap">
                                                    <input type="radio" :id="value3.id + '2'" :name="value3.level" 
                                                      v-model.trim="formInfo.fieldGroups[ind]['fieldGroups'][ind2].fields[ind3].input" 
                                                      v-bind:value="'0'" 
                                                      :disabled="value3.mandatory == 2 || value3.mandatory == 3" 
                                                      @keyup="getValidation($event, value3.name, value3.id, ind, ind2)"
                                                    />
                                                    <label :for="value3.id + '2'" class="radio-label">No</label>
                                                  </div>
                                                </div>
                                              </div>
                                              <span class="error-msg" :id="'error_' + value3.id"></span>
                                            </div>
                                          </div>
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
  
                              <!-- Submit Form Button -->
                               <span v-if="currentTab == 'vehicleDetails'" style="font-size: 12px;color: red;">Entering wrong Chassis Number & Engine Number will result in claim rejection in future.</span>
                              <div class="d-flex gap-4 mt-3">
                                <button type="button" class="btn  backbutton"  @click="goBack()" v-if="currentTab != 'proposerDetails'">
                                  <em class="bi bi-chevron-left me-2"></em>
                                  <span>Previous</span>
                                </button>
                                <button type="submit" class="btn  commonButton">
                                  <span>Proceed</span>
                                  <em class="bi bi-chevron-right ms-2"></em>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <!--Proposer detail form step1-->
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                  
                  
  
                  <!-- Delete Modal Start-->
                  <div class="modal fade" id="savechangesModal" tabindex="-1" aria-labelledby="savechangesModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header border-0">
                          <h5 class="modal-title" id="savechangesModalLabel"></h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-0">
                          <div class="text-center">
                            <h4 class="text-primary">
                              Do you really want to delete Nominee1
                            </h4>
                          </div>
                        </div>
                        <div class="modal-footer justify-content-center border-0">
                          <div class="btn-hold mb-4">
                            <button class="btn btn-sm btn-outline-secondary rounded-pill" type="button">
                              No
                            </button>
                            <button class="btn btn-sm btn-warning text-white rounded-pill" type="button"
                              data-bs-dismiss="modal">
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Delete Modal end-->
                </div>
              </div>
            </div>
          </div>
          <!--row-->
        </div>
      </div>
  
   
      <PageLoader :msg="loadingMsg" v-if="loadingState" />
  
  <!-- </div> -->
</template>
<script>
import { ref } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { getIsAutoComplete } from "@/mixins/common";
import OwnerDetails from "../selectCard/ownerDetails.vue";
import policyDetails from "../selectCard/policyDetails.vue";
import ConfirmPay from "../selectCard/confirmPay.vue";
import EditDetailsModal from "@/modals/editDetailsModal.vue";
import VehicleDetail from "../selectCard/vehicleDetail.vue";
import $ from "jquery";
import PageLoader from "../pageLoaders/pageLoader.vue";
import { getDistributer} from '@/mixins/common';
import ProductDetailsHeader from "../productDetailsHeader/ProductDetailsHeader.vue";

export default {
  name: "formWizard",
  mixins: [getDistributer],
  components: {
    OwnerDetails,
    VehicleDetail,
    policyDetails,
    ConfirmPay,
    EditDetailsModal,
    PageLoader,
    ProductDetailsHeader
  },
  mounted() {
    this.distributorID = getDistributer()["distributerID"];
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.userData, "userData");
    }
    this.selectedPlan = JSON.parse(
      sessionStorage.getItem("selected_plan_data")
    );

    this.requestJson = JSON.parse(sessionStorage.getItem("quote_request"));
    //for expression check
    this.quoteInfo = {
      'quoteResponse': this.selectedPlan,
      'quoteRequest': this.requestJson
    };
    if (sessionStorage.getItem("sp_details_data")) {
      this.spDetails = JSON.parse(sessionStorage.getItem("sp_details_data"));
    }
    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    if (this.rmData) {
      this.agentCode = this.rmData["UserId"];
    }
    // this.distributorID = distributorID;
    this.channel_type = this.requestJson.distributor.channelType;
    this.getProposal();
    // this.fetchKYCDetails();
    this.productCode = this.selectedPlan.insuranceAndProducts.productCode;
  },
  data() {
    return {
        productCode: "",
        loadingState: false,
        loadingMsg:"",
        configData: "",
        currentTab: "proposerDetails",
        noOfAdult: 0,
        noOfChild: 0,
        requestJson: "",
        selectedPlan: "",
        membersInputJson: [],
        selectedPolicyIndex: 0,
        proposalJson: "",
        proposalJson1: "",
        channel_type:"",
        ckycDetails: {},
        formInfo: {},
        quoteInfo: {},
        errorCounter: 0,
        submittedTab: "",
        tabsArr: [],
        userData: "",
        selectedMembersList: [],
        proposalUiId: 0,
        rmData: "",
        applicationNo: "",
        distributorID: "",
        pollRequestId: "",
        quoteResponseID: "",
        responseData: "",
        customModal: false,
        currentSectionIndex: "",
        currentQuoteResponseData: "",
        resume_proposal_flag: "",
        kycData: "",
    };
  },
  methods: {
    fetchKYCDetails(){
      this.ckycDetails = JSON.parse(sessionStorage.getItem("kyc_response"));
      this.requestJson = JSON.parse(sessionStorage.getItem("quote_request"));
    },
    submitForm(sectionIndex) {
        console.log("form submitted: ", sectionIndex);
        let subArray = this.formInfo["fieldGroups"][sectionIndex];
        console.log("subArray: ", subArray);
  
        // ############# Validation Section #############
        this.errorCounter = 0;
        $(".error-msg").text("");
        // If Fields
        var scrollTop = "";
  
        // If Array
        if (this.checkArray(subArray)) {
          for (var key in subArray) {
            // If Fields
            if(subArray[key].fields) {
              let fieldsArr = subArray[key].fields;
              console.log("fieldsArr: ", fieldsArr);
              for (var key2 in fieldsArr) {
                console.log("inside fieldsArr");
                let data = fieldsArr[key2];
                // Evaluating default field
                if(data.default) {
                  // eval(this.getFinalObject(data.default));
                }
                if (
                  this.checkVisibility(data.visibility) &&
                  this.checkVisibility(subArray[key].visibility)
                ) {
                  let errorTxt = this.getDescriptionTextArray(data.description);
                  if (
                    data.mandatory == "1" &&
                    data.type == "checkbox" &&
                    data.input == false
                  ) {
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text("This field is required");
                    console.log("invalid 1  mandatory:", data);
                  } else if (
                    data.type == "multi-select" &&
                    data.mandatory == "1" &&
                    data.input.length == 0
                  ) {
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text("This field is required");
                  } else if (
                    data.mandatory == "1" &&
                    !data.input &&
                    data.input != "0"
                  ) {
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text("This field is required");
                    //console.log('invalid 1 0000 mandatory:', data);
                  } else if (
                    data.input &&
                    data.pattern &&
                    this.checkPattern(data.input, data.pattern) == false
                  ) {
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text(errorTxt[0]);
                    console.log("data.id: ", data.id);
                    console.log("rules 1: ", data.pattern);
                  } else if (
                    data.input &&
                    data.max &&
                    data.type == "number" &&
                    (data.input < data.min || data.input > data.max)
                  ) {
                    console.log("inside number: ", data);
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text(
                      "Value should be between " + data.min + " and " + data.max
                    );
                    console.log(
                      "rules number 1 Incorrect: ",
                      data.validation,
                      data
                    );
                  }else if (
                    data.input &&
                    data.min && data.max &&
                    data.type == "text" &&
                    (data.input.length < data.min || data.input.length > data.max)
                  ) {
                    console.log("inside number: ", data);
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text(
                      "Value should be between " + data.min + " and " + data.max + " Characters"
                    );
                    console.log(
                      "rules number 1 Incorrect: ",
                      data.validation,
                      data
                    );
                  }else if (
                    data.validation != null &&
                    data.input &&
                    this.checkValidation(data.validation, data.description) == false
                  ) {
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id).text(errorTxt[1]);
                    console.log("rules 1 invalid: ", data.validation);
                  }
                } else {
  
                  // setting blank, previously filled data
  
                  if(sectionIndex == 'healthQuestions') {
                    //this.formInfo["fieldGroups"][sectionIndex][key]["fields"][key2]["input"]  = this.membersInputJson
                  } else {
                    this.formInfo["fieldGroups"][sectionIndex][key]["fields"][key2]["input"] = "";
                  }
                }
              }
            }
  
            // If FieldGroups
            if(subArray[key].fieldGroups) {
              console.log('inside array fieldgroup');
              for(var key3 in subArray[key].fieldGroups) {
                let fieldsArr = subArray[key].fieldGroups[key3].fields;
                console.log("fieldsArr: ", fieldsArr);
                for (var key2 in fieldsArr) {
                  console.log("inside fieldsArr");
                  let data = fieldsArr[key2];
                  // Evaluating default field
                  if(data.default) {
                    // eval(this.getFinalObject(data.default));
                  }
                  if (
                    this.checkVisibility(data.visibility) &&
                    this.checkVisibility(subArray[key].visibility)
                  ) {
                    let errorTxt = this.getDescriptionTextArray(data.description);
  
                    console.log('error text id: ', data.id + key);
  
                    if (
                      data.mandatory == "1" &&
                      data.type == "checkbox" &&
                      data.input == false
                    ) {
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text("This field is required");
                      console.log("invalid 1  mandatory:", data);
                    } else if (
                      data.type == "multi-select" &&
                      data.mandatory == "1" &&
                      data.input.length == 0
                    ) {
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text("This field is required");
                    } else if (
                      (data.mandatory == "1" || data.mandatory == "2") &&
                      !data.input &&
                      data.input != "0"
                    ) {
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text("This field is required");
                      //console.log('invalid 1 0000 mandatory:', data);
                    } else if (
                      data.input &&
                      data.pattern &&
                      this.checkPattern(data.input, data.pattern) == false
                    ) {
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text(errorTxt[0]);
                      console.log("data.id: ", data.id);
                      console.log("rules 1: ", data.pattern);
                    } else if (
                      data.input &&
                      data.max &&
                      data.type == "number" &&
                      (data.input < data.min || data.input > data.max)
                    ) {
                      console.log("inside number: ", data);
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text(
                        "Value should be between " + data.min + " and " + data.max
                      );
                      console.log(
                        "rules number 1 Incorrect: ",
                        data.validation,
                        data
                      );
                    }else if (
                      data.input &&
                      data.min && data.max &&
                      data.type == "text" &&
                      (data.input.length < data.min || data.input.length > data.max)
                    ) {
                      console.log("inside number: ", data);
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text(
                        "Value should be between " + data.min + " and " + data.max
                      );
                      console.log(
                        "rules number 1 Incorrect: ",
                        data.validation,
                        data
                      );
                    }else if (
                      data.validation != null &&
                      data.input &&
                      this.checkValidation2(this.getExpWithIndex(data.validation, key), data.description) == false
                    ) {
                      this.errorCounter++;
                      if (scrollTop == "") {
                        scrollTop = data.id + key;
                      }
                      $("#error_" + data.id + key).text(errorTxt[1]);
                      console.log("rules 1 invalid: ", data.validation);
                    }
                  } else {
                    // setting blank, previously filled data
                    if(sectionIndex == 'healthQuestions') {
                      //this.formInfo["fieldGroups"][sectionIndex][key]["fields"][key2]["input"] = this.membersInputJson;
                    } else {
                      this.formInfo["fieldGroups"][sectionIndex][key]["fields"][key2]["input"] = "";
                    }
                    
                  }
                }
              }
  
                
            }
            
          }
        }
  
        // If Fields
        for (var key in subArray.fields) {
          let data = subArray.fields[key];
          // Evaluating default field
          if(data.default) {
            // eval(this.getFinalObject(data.default));
          }
          if (this.checkVisibility2(data.visibility, sectionIndex)) {
            let errorTxt = this.getDescriptionTextArray(data.description);
            if(sectionIndex == 'healthQuestions' && this.checkArray(data.input)) {
              
              data.input.forEach((element, index) => {
                if(this.checkVisibility(this.getExpWithIndex(data.visibility, index))) {
                  if(data.mandatory == "1" && !data?.input[index]["answer"]) {
                    this.errorCounter++;
                    console.log('Health error 1: mandatory: ', data);
                    $("#error_" + data.id + '_' + index).text("This field is required");
                  } else if(data.type == "number" && (data?.input[index]["answer"] < data.min ||  data?.input[index]["answer"] > data.max)) {
                    console.log("Health error 2: number min max", data);
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id + '_' + index).text(
                      "Value should be between " + data.min + " and " + data.max
                    );
                  }else if(data.type == "text" && (data?.input[index]["answer"].length < data.min ||  data?.input[index]["answer"].length > data.max)) {
                    console.log("Health error 2: number min max", data);
                    this.errorCounter++;
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id + '_' + index).text(
                      "Value should be between " + data.min + " and " + data.max + " Characters"
                    );
                  }else if(this.checkValidation2(this.getExpWithIndex(data.validation, index), data.description) == false) {
                    this.errorCounter++;
                    console.log('');
                    if (scrollTop == "") {
                      scrollTop = data.id;
                    }
                    $("#error_" + data.id + '_' + index).text(errorTxt[1]);
                    console.log("Health error 3: validation: ", data);
                    if ($("#"+data.id+'_section').hasClass("d-none")) {
                      $("#"+data.id+'_section').removeClass("d-none")
                    }
                  } 
                  
                  // Add error class to dropdowns
                  this.getErrorClassForDropdown('dropdownMenuButton1' + data.id);
                }
              });
              
              
              
            } else {
              if (
                data.mandatory == "1" &&
                data.type == "checkbox" &&
                data.input == false
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text("This field is required");
                console.log("invalid 1  mandatory:", data);
              } else if (
                data.mandatory == "1" &&
                !data.input &&
                data.input != "0"
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                // console.log(scrollTop);
                console.log("invalid fields: mandatory: dt", data);
                $("#error_" + data.id).text("This field is required");
              } else if (
                data.input &&
                data.pattern &&
                this.checkPattern(data.input, data.pattern) == false
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(errorTxt[0]);
                console.log("data.id: ", data.id);
                console.log("rules 1 Incorrect: ", data.pattern);
              } else if (
                data.input &&
                data.max &&
                data.type == "number" &&
                (data.input < data.min || data.input > data.max)
              ) {
                console.log("inside number: f ", data);
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(
                  "Value should be between " + data.min + " and " + data.max
                );
                console.log("rules number Incorrect: ", data.validation);
              }else if (
                data.input &&
                data.max && data.min &&
                data.type == "text" &&
                (data.input.length < data.min || data.input.length > data.max)
              ) {
                console.log("inside number: f ", data);
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(
                  "Value should be between " + data.min + " and " + data.max + " Characters"
                );
                console.log("rules number Incorrect: ", data.validation);
              }else if (
                data.validation &&
                data.input && this.checkValidation(data.validation, data.description) == false
              ) {
                  this.errorCounter++;
                  console.log('inside checkvalidation pppppppppppp')
                  if (scrollTop == "") {
                    scrollTop = data.id;
                  }
                  $("#error_" + data.id).text(errorTxt[1]);
                  console.log("rules 1 invalid: ", data.validation);
              }
            }
            
          } else {
            // setting blank, previously filled data
            if(sectionIndex == 'healthQuestions') {
              //this.formInfo["fieldGroups"][sectionIndex]["fields"][key]["input"] = this.membersInputJson;
            } else {
              this.formInfo["fieldGroups"][sectionIndex]["fields"][key]["input"] ="";
            }
          }
        }
  
        // if (scrollTop != '') {
        //     var ele = document.getElementById(scrollTop);
        //     ele.scrollIntoView();
        //     ele.focus();
        //     ele.placeholder = ele.getAttribute("ph");
        // }
  
        // If FieldGroups
        for (var key in subArray.fieldGroups) {
          console.log('field groups key: ', key);
          let fieldsArr = subArray.fieldGroups[key].fields;
          for (var key2 in fieldsArr) {
            let data = fieldsArr[key2];
            // Evaluating default field
            if(data.default) {
              // eval(this.getFinalObject(data.default));
            }
            if (
              this.checkVisibility(data.visibility) &&
              this.checkVisibility(subArray.fieldGroups[key].visibility)
            ) {
              let errorTxt = this.getDescriptionTextArray(data.description);
              if (
                data.mandatory == "1" &&
                data.type == "checkbox" &&
                data.input == false
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text("This field is required");
                console.log("invalid 1  mandatory:", data);
              } else if (
                data.type == "multi-select" &&
                data.mandatory == "1" &&
                data.input.length == 0
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text("This field is required");
              } else if (
                data.mandatory == "1" &&
                !data.input &&
                data.input != "0"
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text("This field is required");
                //console.log('invalid 1 0000 mandatory:', data);
              } else if (
                data.input &&
                data.pattern &&
                this.checkPattern(data.input, data.pattern) == false
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(errorTxt[0]);
                console.log("data.id: ", data.id);
                console.log("rules 1: ", data.pattern);
              } else if (
                data.input &&
                data.max &&
                data.type == "number" &&
                (data.input < data.min || data.input > data.max)
              ) {
                console.log("inside number: ", data);
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(
                  "Value should be between " + data.min + " and " + data.max
                );
                console.log("rules number 1 Incorrect: ", data.validation, data);
              }else if (
                data.input &&
                data.max && data.min &&
                data.type == "text" &&
                (data.input.length < data.min || data.input.length > data.max)
              ) {
                console.log("inside number: ", data);
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(
                  "Value should be between " + data.min + " and " + data.max + " Characters"
                );
                console.log("rules number 1 Incorrect: ", data.validation, data);
              }else if (
                data.validation != null &&
                data.input &&
                this.checkValidation(data.validation, data.description) == false
              ) {
                this.errorCounter++;
                if (scrollTop == "") {
                  scrollTop = data.id;
                }
                $("#error_" + data.id).text(errorTxt[1]);
                console.log("rules 1 invalid: ", data.validation);
              }
            
            } else {
              // setting blank, previously filled data
              if(sectionIndex == 'healthQuestions') {
                //this.formInfo["fieldGroups"][sectionIndex]["fieldGroups"][key]["fields"][key2]["input"] =  this.membersInputJson;
              } else {
                this.formInfo["fieldGroups"][sectionIndex]["fieldGroups"][key]["fields"][key2]["input"] = "";
              }
            }
          }
        }
  
        if (scrollTop != "" && ele) {
          var ele = document.getElementById(scrollTop);
          console.log('kkkkkkkkk: ', scrollTop);
          ele.scrollIntoView();
          ele.focus();
          //ele.placeholder = ele.getAttribute("ph");
        }
  
        console.log("this.errorCounter: ", this.errorCounter);
        //console.log("formInfo['fieldGroups']['insuredMembers']['0']['fieldGroups']['insuredMemberPersonalDetails']['fields']['gender']['input']: ", this.formInfo['fieldGroups']['insuredMembers']['0']['fieldGroups']['insuredMemberPersonalDetails']['fields']['gender']['input']);
        //console.log("formInfo['fieldGroups']['insuredMembers']['1']['fieldGroups']['insuredMemberPersonalDetails']['fields']['gender']['input']: ", this.formInfo['fieldGroups']['insuredMembers']['1']['fieldGroups']['insuredMemberPersonalDetails']['fields']['gender']['input']);
        if (this.errorCounter == "0") {
          this.submittedTab = sectionIndex;
  
          // If submitted index is proposer details
          
  
          // If submitted index is insured members
          if(this.submittedTab == 'insuredMembers') {
            subArray.forEach((element, index) => {
              let dob = element["fieldGroups"]["insuredMemberPersonalDetails"]["fields"]["dateOfBirth"]["input"];
              this.requestJson.personalInformation.individualDetails[index]["dateOfBirth"] = dob;
              this.requestJson.personalInformation.individualDetails[index]["age"] = this.calculateAge(dob);
            })
  
            console.log('Updated request json = ', this.requestJson);
            this.getQuotationApi(this.requestJson, sectionIndex);
          } else {
            this.saveProposal(sectionIndex);
          }
  
          console.log("submitted data modified: ", this.formInfo);
  
          // Calling Save Proposal
          //this.saveProposal(sectionIndex);  
          //this.activeNextTab(sectionIndex);
        } else {
          //this.activeNextTab(sectionIndex);
        }
      },
      getDescriptionTextArray(str) {
        let tempArr = [];
        if (str) {
          var check = str.includes("#");
          if (check) {
            tempArr = str.split("#");
          } else {
            tempArr[0] = str;
            tempArr[1] = str;
          }
        } else {
          tempArr[0] = "";
          tempArr[1] = "";
        }
  
        return tempArr;
      },
    sortDropDown(list) {
        //return list;
        if (list) {
          list = list.sort(sortbyname);
          function sortbyname(a, b) {
            if (a.Text < b.Text) return -1;
            if (a.Text > b.Text) return 1;
            return 0;
          }
          return list;
        }
      },
      validateYear(field, value){
        if(field == "proposerDetails"){
          const dateParts = value.split("-")
          if (dateParts[0] && dateParts[0].length > 4) {
            dateParts[0] = dateParts[0].slice(0, 4);
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input = dateParts.join("-");
          }
        }else if(field =="nomineeInfo"){
          const dateParts1 = value.split("-")
          if (dateParts1[0] && dateParts1[0].length > 4) {
            dateParts1[0] = dateParts1[0].slice(0, 4);
            this.formInfo.fieldGroups.nomineeInfo.fields.dateOfBirth.input = dateParts1.join("-");
          }
        }else if(field =="previousPolicyDetails"){
          const dateParts2 = value.split("-")
          if (dateParts2[0] && dateParts2[0].length > 4) {
            dateParts2[0] = dateParts2[0].slice(0, 4);
            this.formInfo.fieldGroups.previousPolicyDetails.fieldGroups.ownDamagePolicyDetails.fields.previousPolicyEndDate.input = dateParts2.join("-");
          }
        }
      },
    getValidation(event, name, id, ind1 = "", ind2 = "", ind3 = "") {
      if (event.target.type === "number") {
        const value = event.target.value;
        event.target.value = value.replace(/[^0-9.]/g, "");
      }
      self = this;
      $("#error_" + id).text("");
      let val = event.target.value;
      // console.log('getValidation called');
      // console.log('ev: ', event);
      // console.log('id: ', id);
      console.log("event: ", val);
      console.log("field name: ", name);
      console.log("ind1: ", ind1, "ind2: ", ind2, "ind3: ", ind3);

      if (name == "PAN") {
        console.log(
          "inside pan: ",
          this.formInfo.fieldGroups[ind1].fieldGroups[ind2].fields[name].input
        );
        this.formInfo.fieldGroups[ind1].fieldGroups[ind2].fields[name].input =
          this.formInfo.fieldGroups[ind1].fieldGroups[ind2].fields[name][
            "input"
          ].toUpperCase();
      }

      if (
        this.selectedPlan.insuranceAndProducts.insuranceCompanyCode === "TAGIC"
      ) {
        if (ind1 === "healthQuestions" && val === "0") {
          const miniIndex = ind2?.substring(3, 0);
          const allIndexs = Object.keys(this.formInfo.fieldGroups[ind1].fields)
            .filter((ele) => {
              return ele?.startsWith(miniIndex);
            })
            .slice(1);
          allIndexs.forEach((indexName) => {
            this.formInfo.fieldGroups[ind1].fields[indexName].input.forEach(
              (ele) => {
                this.formInfo.fieldGroups[ind1].fields[indexName].input[
                  ind3
                ].answer = "";
              }
            );
          });
        }
      }

      if (name == "weight" || name == "height") {
        // let testString = this.formInfo["fieldGroups"][ind1][ind2]["fieldGroups"][ind3]["fields"]["insuredBMI"]["default"];
        // eval(this.getFinalObject(testString));
      }
      console.log("len: ", val.length);
      if (name == "zipCode" && val.length == 6) {
        console.log("zipcode completed");
        this.getPincodeDetails2(val, ind1, ind2, ind3);
      }

      // if(name === 'cityDistrict')
      // {
      //   console.log("get Area completed",val,name)
      //   this.getAreaCode(val,ind1, ind2, ind3)
      // }

      if (name === "town") {
        this.getAreaCode(val, ind1, ind2, ind3);
      }

      if (name == "maritalStatus") {
        this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
          "title"
        ]["input"] = this.getTitleStr(
          val,
          this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
            "gender"
          ]["input"]
        );
      }

      //console.log('path: ', path);
    },
    getPincodeDetails2(pincode, ind1, ind2) {
      const Insurence = JSON.parse(
        sessionStorage.getItem("selected_plan_data")
      );

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      let reqData = [pincode];
      this.loadingState=true;
      this.loadingMsg="Getting Pin Code details"
      //this.loading = true;
      this.backendApi("getPincodeInfo", reqData, headers, "get")
        .then((response) => {
          this.loadingState=false;
          if (response == "error") {
            //this.apiError = true;
            console.log("pincode api error: block");
          } else if (response.data.errors.length > 0) {
            this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
              "zipCode"
            ]["input"] = "";
            this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
              "cityDistrict"
            ]["input"] = "";
            if (
              Insurence.insuranceAndProducts.insuranceCompanyCode === "STAR"
            ) {
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "area"
              ]["input"] = "";
            }
            this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
              "state"
            ]["input"] = "";
            this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
              "nationality"
            ]["input"] = "";
          } else if (response && response.data) {
            this.zipCodeInside = pincode;

            if (
              Insurence.insuranceAndProducts.insuranceCompanyCode === "STAR"
            ) {
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "area"
              ]["input"] = "";
              let v = response.data.data[0];
              sessionStorage.setItem("pincode_data", JSON.stringify(v));
              this.getCityCode(pincode, ind1, ind2);
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "cityDistrict"
              ]["input"] = v?.city;
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "state"
              ]["input"] = v.state;
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "nationality"
              ]["input"] = v.country;
            } else {
              let v = response.data.data[0];
              sessionStorage.setItem("pincode_data", JSON.stringify(v));
              // Setting Pincode Data
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "cityDistrict"
              ]["input"] = v.city;
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "state"
              ]["input"] = v.state;
              this.formInfo["fieldGroups"][ind1]["fieldGroups"][ind2]["fields"][
                "nationality"
              ]["input"] = v.country;
            }
          }
        })
        .catch((error) => {
          this.loadingState=false;
          console.log("error msg block getting pincode info", error);
          //this.loading = false;
          //this.apiError = true;
        });
    },
    getDescriptionText(str) {
      let descText = "";
      if (str) {
        var check = str.includes("#");
        if (check) {
          let tempArr = str.split("#");
          descText = tempArr[0];
        } else {
          descText = str;
        }
      } else {
        descText = "";
      }
      return descText;
    },
    completed(index) {
      this.activeTab = index;
    },
    isCompleted(index) {
      return index < this.activeTab;
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(
        /'input' in formInfo\[/g,
        "formInfo["
      );
      var updatedString2 = updatedString.replace(
        /formInfo\[/g,
        "this.formInfo["
      );
      var updatedString3 = updatedString2.replace(
        /quoteInfo\[/g,
        "this.quoteInfo["
      );
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString3);
    },
    getDescriptionText(str) {
      let descText = "";
      if (str) {
        var check = str.includes("#");
        if (check) {
          let tempArr = str.split("#");
          descText = tempArr[0];
        } else {
          descText = str;
        }
      } else {
        descText = "";
      }
      return descText;
    },
    getProposal() {
      // this.loadingMessage = 'Please wait a moment while we verify details!';
      this.loadingState = true;
      this.loadingMsg="Fetching Proposal Details"
      var self = this;
      this.errMsg = "";
      let reqData = {
        productId: this.selectedPlan.insuranceAndProducts.productCode,
        manufacturerId:
          this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
        version: 1,
      };
      let headers = { Authorization: `Bearer ${this.$store.state.token}` };
      this.backendApi("getProposal", reqData, headers, "get")
        .then((response) => {
          this.loadingState = false;
          //console.log("proposal response: ", response);

          if (response.status == "error") {
            let msg = [response.data.errors[0].errorDisplayMsg];
            this.errMsg = msg;
            // this.$router.push({ path: '/error-page', query: { backUrl, msg: msg } });
          } else if (response.data.errors.length > 0) {
            let msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorDisplayMessage"]);
            }
            // this.$router.push({ path: '/error-page', query: { backUrl, msgError: "We are facing some issue while fetching proposal data", reqId: response.data.reqId, msg: msg } });
            this.errMsg = msg;
          } else {
            console.log(response.data.data, "response.data.data");
            this.proposalFormGenerate(response.data.data);
          }
        })
        .catch((error) => {
          this.loadingState=false;
          console.log("error msg block : get proposal", error);
          self.loading = false;
          // self.apiError = true;
          let msg = [
            "We are facing some issue from while fetching Proposal API",
          ];
          this.errMsg = msg;

          // this.$router.push({ path: '/error-page', query: {backUrl:'policy-quote', msg: msg }});
        });
    },
    checkArray(data) {
      return Array.isArray(data);
    },
    getSalutation2(gen) {
      if (gen == "Male") {
        return "mr";
      } else {
        let itemIndex = this.userData.memberList.findIndex(
          (item) => item.member == "Spouse"
        );
        if (itemIndex > -1) {
          return "mrs";
        } else {
          return "ms";
        }
      }
    },
    proposalFormGenerate(proposalForm) {
      this.proposalJson = proposalForm;
      // console.log(this.proposalJson);
      this.formInfo = this.proposalJson;
      // Removing traceInfo and distributer attriutes from proposal json visibility
      //delete this.proposalJson.fieldGroups.traceInfo;
      //delete this.proposalJson.fieldGroups.distributor;
      this.proposalJson.fieldGroups.traceInfo.visibility = "false";
      this.proposalJson.fieldGroups.distributor.visibility = "false";
      //console.log('after deleted traceinfo and distributer: ', this.formInfo);

      // Temprary update for test purpose
      let arr1 = [{ Text: "Maharastra", Value: "maharastra" }];
      let arr2 = [{ Text: "Indian", Value: "in" }];
      let arr3 = [{ Text: "Father", Value: "father" }];
      //this.proposalJson.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.value = arr1;
      //this.proposalJson.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.nationality.value = arr2;
      //this.proposalJson.fieldGroups.nomineeInfo.fields.nomineeRelationWithProposer.value = arr3;
      // End temprary update for test purpose

      for (var key in this.proposalJson.fieldGroups) {
        if (key != "traceInfo" && key != "distributor") {
          if (this.checkArray(this.proposalJson.fieldGroups[key])) {
            this.tabsArr.push(this.proposalJson.fieldGroups[key][0]["name"]);
          } else {
            this.tabsArr.push(this.proposalJson.fieldGroups[key]["name"]);
          }
        }
      }
      if(this.formInfo['fieldGroups']['previousPolicyDetails']['fieldGroups']['ownDamagePolicyDetails']['fields']['previousPolicyEndDate']['default']) {
        console.log("hello",this.quoteInfo)
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['previousPolicyDetails']['fieldGroups']['ownDamagePolicyDetails']['fields']['previousPolicyEndDate']['default']));
                }
      //console.log("this.tabsArr: ", this.tabsArr);
      //setting ckyc details
      let kycData = sessionStorage.getItem("kyc_response") ? JSON.parse(sessionStorage.getItem("kyc_response")) : "";

      if (kycData) {
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['gender']['input'] = String(kycData['personalInformation']['gender']).charAt(0).toUpperCase() + String(kycData['personalInformation']['gender']).slice(1).toLowerCase();
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['gender']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['gender']['default']));
                }
        } catch (err) {
          console.log('kyc data title mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input'] = kycData['personalInformation']['firstName'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['default']));
                }
        } catch (err) {
          console.log('kyc data firstName mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['middleName']['input'] = kycData['personalInformation']['middleName'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['middleName']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['middleName']['default']));
                }
        } catch (err) {
          console.log('kyc data middleName mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input'] = kycData['personalInformation']['lastName'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['default']));
                }
        } catch (err) {
          console.log('kyc data lastName mapping error:', err);
        }
        // try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['mobileNumber']['input'] =  this.userData["mobileNumber"];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['mobileNumber']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['mobileNumber']['default']));
                }
        // } catch (err) {
        //   console.log('kyc data mobileNumber mapping error:', err);
        // }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['PAN']['input'] = kycData['personalInformation']['pan'];
          if( this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['PAN']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['PAN']['default']));
                }
        } catch (err) {
          console.log('kyc data PAN mapping error:', err);
        }
        // try {
        //   this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['gender']['input'] = this.userData.gender.toLowerCase();
        // } catch (err) {
        //   console.log('kyc data gender mapping error:', err);
        // }
        // this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['maritalStatus']['input'] = this.getMaritalStatusForProposer(); PFM-8629
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['dateOfBirth']['input'] = this.getDateFormat(kycData?.personalInformation?.dateOfBirth);
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['dateOfBirth']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['dateOfBirth']['default']));
                }
        } catch (err) {
          console.log('kyc data dateOfBirth mapping error:', err);
        }
        // Communication Address
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address1']['input'] = kycData['communicationAddressDetails']['address1'];
          if(  this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address1']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address1']['default']));
                }
        } catch (err) {
          console.log('kyc data address1 mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address2']['input'] = kycData['communicationAddressDetails']['address2'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address2']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address2']['default']));
                }
        } catch (err) {
          console.log('kyc data address2 mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address3']['input'] = kycData['communicationAddressDetails']['address3'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address3']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['address3']['default']));
                }
        } catch (err) {
          console.log('kyc data address3 mapping error:', err);
        }
        try {
        if(kycData['communicationAddressDetails']['zipcode'] !=""){
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['input'] = kycData['communicationAddressDetails']['zipcode'];
          if( this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['default']));
                }
        }
        else{
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['input'] = this.userData["pinCode"];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['communicationAddressDetails']['fields']['zipCode']['default']));
                }
        }
        } catch (err) {
          console.log('kyc data zipCode mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['identificationDetails']['fields']['cKYCId']['input'] = kycData['kycInfo']['Manufacturer_KYC_Id'];
        } catch (err) {
          console.log('kyc data cKYCId mapping error:', err);
        }
        // Permanent Address
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address1']['input'] = kycData['permanentAddressDetails']['address1'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address1']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address1']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails address1 mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address2']['input'] = kycData['permanentAddressDetails']['address2'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address2']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address2']['default']));
                }
                if(this.formInfo['fieldGroups']['vehicleDetails']['fieldGroups']['odometerDetails']['fields']['odometerReading']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['vehicleDetails']['fieldGroups']['odometerDetails']['fields']['odometerReading']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails address2 mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address3']['input'] = kycData['permanentAddressDetails']['address3'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address3']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['address3']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails address3 mapping error:', err);
        }
        try {
          if(kycData['permanentAddressDetails']['zipcode'] !=""){
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['input'] = kycData['permanentAddressDetails']['zipcode'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['default']));
                }
          }
          else{
            this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['input'] = this.userData["pinCode"];
            if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['zipCode']['default']));
                }
          }
        } catch (err) {
          console.log('kyc data permanentAddressDetails zipCode mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['cityDistrict']['input'] = kycData['permanentAddressDetails']['citydistrict'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['cityDistrict']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['cityDistrict']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails cityDistrict mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['state']['input'] = kycData['permanentAddressDetails']['state'];
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['state']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['state']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails state mapping error:', err);
        }
        try {
          this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['sameAddress']['input'] = '0';
          if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['sameAddress']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['sameAddress']['default']));
                }
        } catch (err) {
          console.log('kyc data permanentAddressDetails sameAddress mapping error:', err);
        }
        if (kycData['communicationAddressDetails']['zipcode']) {
            this.getPincodeDetails(kycData['communicationAddressDetails']['zipcode']);
            this.getPincodeDetails3(kycData['permanentAddressDetails']['zipcode']);
            if(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['nationality']['default']) {
                  eval(this.getFinalObject(this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['permanentAddressDetails']['fields']['nationality']['default']));
                }
          }
          else{
          if (this.userData.pinCode) {
            if (this.userData.pinCode) {
              this.getPincodeDetails(this.userData.pinCode);
              this.getPincodeDetails3(this.userData.pinCode);
              this.zipCodeInside = this.userData.pinCode;
            }
          }
        }

        // Setting Pin code section 
        // if (JSON.parse(sessionStorage.getItem("proposal_review_flag")) != '1') {
          // if (kycData['communicationAddressDetails']['zipcode']) {
          //   this.getPincodeDetails(kycData['communicationAddressDetails']['zipcode']);
          //   this.getPincodeDetails3(kycData['permanentAddressDetails']['zipcode']);
          // }
        // }

      }
     
      


      // Setting up previously filled data
      // const userData =
      //   this.requestJson.personalInformation.individualDetails[0];

      // const addressData =
      //   this.proposalJson.fieldGroups.proposerDetails.fieldGroups
      //     .permanentAddressDetails.fields;
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["title"]["input"] = this.getSalutation2(
      //     this.userData.gender
      //   );
      // } catch (err) {
      //   console.log("landing page data mapping error for title:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["firstName"]["input"] = userData["firstName"]
      //     ? userData["firstName"]
      //     : this.userData["contactDetails"]["firstName"];
      // } catch (err) {
      //   console.log("landing page data mapping error for firstName:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["middleName"]["input"] = userData["middleName"]
      //     ? userData["middleName"]
      //     : this.userData["contactDetails"]["middleName"];
      // } catch (err) {
      //   console.log("landing page mapping error for middleName:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["lastName"]["input"] = userData["lastName"]
      //     ? userData["lastName"]
      //     : this.userData["contactDetails"]["lastName"];
      // } catch (err) {
      //   console.log("landing page mapping error for lastName:", err);
      // }

      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["dateOfBirth"]["input"] = userData["dateOfBirth"]
      //     ? userData["dateOfBirth"]
      //     : this.userData["contactDetails"]["dateOfBirth"];
      // } catch (err) {
      //   console.log("landing page mapping error for dob:", err);
      // }

      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["maritalStatus"]["input"] = userData["maritalStatus"]
      //     ? userData["maritalStatus"].toLowerCase()
      //     : this.userData["contactDetails"]["maritalStatus"];
      // } catch (err) {
      //   console.log("landing page mapping error for marital status:", err);
      // }

      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["mobileNumber"]["input"] = userData["mobileNumber"]
      //     ? userData["mobileNumber"].toString()
      //     : this.userData["contactDetails"]["mobileNumber"];
      // } catch (err) {
      //   console.log("landing page mapping error for mobileNumber:", err);
      // }
      // // Pan not avaiable
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["email"]["input"] = userData["email"]
      //     ? userData["email"]
      //     : this.userData["contactDetails"]["emailId"];
      // } catch (err) {
      //   console.log("landing page emailis mapping error for email:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "personalDetails"
      //   ]["fields"]["gender"]["input"] = userData.gender
      //     ? userData.gender.toLowerCase()
      //     : this.userData.gender.toLowerCase();
      // } catch (err) {
      //   console.log("landing page gender mapping error for gender:", err);
      // }

      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "communicationAddressDetails"
      //   ]["fields"]["address1"]["input"] = userData["address1"]
      //     ? userData["address1"]
      //     : addressData["address1"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "communicationAddressDetails"
      //   ]["fields"]["address2"]["input"] = userData["address2"]
      //     ? userData["address2"]
      //     : addressData["address2"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "communicationAddressDetails"
      //   ]["fields"]["address3"]["input"] = userData["address3"]
      //     ? userData["address3"]
      //     : addressData["address3"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "communicationAddressDetails"
      //   ]["fields"]["zipCode"]["input"] = userData["zipCode"]
      //     ? userData["zipCode"]
      //     : addressData["zipCode"].input
      //     ? addressData["zipCode"]?.input
      //     : this.userData.pinCode;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "identificationDetails"
      //   ]["fields"]["cKYCId"]["input"] = userData["Manufacturer_KYC_Id"];
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // // Permanent Address
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["address1"]["input"] = userData["address1"]
      //     ? userData["address1"]
      //     : addressData["address1"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["address2"]["input"] = userData["address2"]
      //     ? userData["address2"]
      //     : addressData["address2"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["address3"]["input"] = userData["address3"]
      //     ? userData["address3"]
      //     : addressData["address3"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["zipCode"]["input"] = userData["zipCode"]
      //     ? userData["zipCode"]
      //     : addressData["zipCode"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["cityDistrict"]["input"] = userData["citydistrict"]
      //     ? userData["citydistrict"]
      //     : addressData["citydistrict"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["state"]["input"] = userData["state"]
      //     ? userData["state"]
      //     : addressData["state"].input;
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }
      // try {
      //   this.formInfo["fieldGroups"]["proposerDetails"]["fieldGroups"][
      //     "permanentAddressDetails"
      //   ]["fields"]["sameAddress"]["input"] = "0";
      // } catch (err) {
      //   console.log("kyc data mapping error:", err);
      // }

      // if (this.userData.pinCode) {
      //   if (this.userData.pinCode) {
      //     this.getPincodeDetails(this.userData.pinCode);
      //     this.getPincodeDetails3(this.userData.pinCode);
      //     this.zipCodeInside = this.userData.pinCode;
        //   }
      // }

      // let spdetail_flag = process.env.VUE_APP_SPDETAIL_ONESB;
      // if (this.spDetails != null) {
      //   if (spdetail_flag == 1) {
      //     if (this.formInfo["fieldGroups"]["distributor"]["fields"]["spCode"]) {
      //       this.formInfo["fieldGroups"]["distributor"]["fields"][
      //         "spCode"
      //       ].input = this.spDetails.spCode;
      //       console.log(
      //         "inside sp details:",
      //         this.formInfo["fieldGroups"]["distributor"]["fields"]["spCode"]
      //           .input
      //       );
          //     }
      //   }
      // }
      const isPreFilled = getDistributer()['isPreFilled'];
      if(isPreFilled && this.$store.state.customerData){
        const customerData = this.$store.state.customerData;
        try {
          if(this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email){
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input = customerData.personalInformation.email;
          }
        } catch (error) { }

        try {
          if(this.formInfo.fieldGroups.nomineeInfo.fields.fullName){
            this.formInfo.fieldGroups.nomineeInfo.fields.fullName.input = `${customerData.nomineeDetails.firstName} ${customerData.nomineeDetails.middleName || ''} ${customerData.nomineeDetails.lastName}`.replace(/\s+/g, ' ').trim();

          }
        } catch (error) { }

        try {
          if(this.formInfo.fieldGroups.nomineeInfo.fields.dateOfBirth){
            this.formInfo.fieldGroups.nomineeInfo.fields.dateOfBirth.input = customerData.nomineeDetails.dateOfBirth;
          }
        } catch (error) { }

        try {
          if(this.formInfo.fieldGroups.nomineeInfo.fields.nomineeRelationWithProposer){
            this.formInfo.fieldGroups.nomineeInfo.fields.nomineeRelationWithProposer.input = customerData.nomineeDetails.relationship;
          }
        } catch (error) { }
      }
      let self = this;
      let filledData = JSON.parse(sessionStorage.getItem("filledData"));
      if (filledData) {
        if (this.productCode == filledData.productId) {
          let filledDataTab = JSON.parse(
            sessionStorage.getItem("filledDataTab")
          );

          for (var key in filledData.fieldGroups) {
            let data = filledData["fieldGroups"][key];

            // If Array
            if (this.checkArray(data)) {
              let subArray = filledData["fieldGroups"][key];
              for (var key_1 in subArray) {
                // If Fields
                if (subArray[key_1].fields) {
                  let fieldsArr = subArray[key_1].fields;
                  console.log("fieldsArr: ", fieldsArr);
                  for (var key_2 in fieldsArr) {
                    let data2 =
                      filledData["fieldGroups"][key][key_1]["fields"][key_2];
                  }
                }

                // If FieldGroups
                if (subArray[key_1].fieldGroups) {
                  //console.log('inside array fieldgroup');
                  for (var key_2 in subArray[key_1].fieldGroups) {
                    let fieldsArr = subArray[key_1].fieldGroups[key_2].fields;
                    //console.log("fieldsArr: ", fieldsArr);
                    for (var key_3 in fieldsArr) {
                      //console.log("inside fieldsArr");
                      let data3 =
                        filledData["fieldGroups"][key][key_1]["fieldGroups"][
                          key_2
                        ]["fields"][key_3];

                      if (data3.type == "multi-select") {
                        try {
                          this.formInfo["fieldGroups"][key][key_1][
                            "fieldGroups"
                          ][key_2]["fields"][key_3]["input"] =
                            filledData["fieldGroups"][key][key_1][
                              "fieldGroups"
                            ][key_2]["fields"][key_3]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          //console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                        }
                      } else if (data3.type == "boolean") {
                        try {
                          this.formInfo["fieldGroups"][key][key_1][
                            "fieldGroups"
                          ][key_2]["fields"][key_3]["input"] =
                            filledData["fieldGroups"][key][key_1][
                              "fieldGroups"
                            ][key_2]["fields"][key_3]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          //console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                        }
                      } else if (data3.type == "number") {
                        try {
                          this.formInfo["fieldGroups"][key][key_1][
                            "fieldGroups"
                          ][key_2]["fields"][key_3]["input"] = parseInt(
                            filledData["fieldGroups"][key][key_1][
                              "fieldGroups"
                            ][key_2]["fields"][key_3]["input"]
                          );
                        } catch (err) {
                          console.log("error during assignment:", err);
                          //console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                        }
                      } else if (data3.type == "currency") {
                        try {
                          console.log(
                            "currency in filled data",
                            filledData["fieldGroups"][key][key_1][
                              "fieldGroups"
                            ][key_2]["fields"][key_3]["input"]
                          );
                          this.formInfo["fieldGroups"][key][key_1][
                            "fieldGroups"
                          ][key_2]["fields"][key_3]["input"] =
                            new Intl.NumberFormat("en-IN").format(
                              parseInt(
                                filledData["fieldGroups"][key][key_1][
                                  "fieldGroups"
                                ][key_2]["fields"][key_3]["input"]
                              )
                            );
                        } catch (err) {
                          console.log("error during assignment:", err);
                          //console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                        }
                      } else {
                        try {
                          this.formInfo["fieldGroups"][key][key_1][
                            "fieldGroups"
                          ][key_2]["fields"][key_3]["input"] =
                            filledData["fieldGroups"][key][key_1][
                              "fieldGroups"
                            ][key_2]["fields"][key_3]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          //console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                        }
                      }
                    }
                  }
                }
              }
            }
            // End : If Array

            if (data.fields) {
              for (var key2 in data.fields) {
                let data2 = filledData["fieldGroups"][key]["fields"][key2];
                if (data2.type == "multi-select") {
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    filledData["fieldGroups"][key]["fields"][key2]["input"];
                } else if (data2.type == "boolean") {
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    filledData["fieldGroups"][key]["fields"][key2]["input"];
                } else if (data2.type == "number" && key == "healthQuestions") {
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    filledData["fieldGroups"][key]["fields"][key2]["input"];
                } else if (data2.type == "number") {
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    parseInt(
                      filledData["fieldGroups"][key]["fields"][key2]["input"]
                    );
                } else if (data2.type == "currency") {
                  console.log(
                    "currency in filled data",
                    filledData["fieldGroups"][key]["fields"][key2]["input"]
                  );
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    new Intl.NumberFormat("en-IN").format(
                      parseInt(
                        filledData["fieldGroups"][key]["fields"][key2]["input"]
                      )
                    );
                } else {
                  this.formInfo["fieldGroups"][key]["fields"][key2]["input"] =
                    filledData["fieldGroups"][key]["fields"][key2]["input"];
                }
              }
            }

            // fieldGroups
            if (data.fieldGroups) {
              for (var key2 in data.fieldGroups) {
                let data2 = filledData["fieldGroups"][key]["fieldGroups"][key2];
                for (var key3 in data2.fields) {
                  let data3 =
                    filledData["fieldGroups"][key]["fieldGroups"][key2][
                      "fields"
                    ][key3];
                  if (data3.type == "multi-select") {
                    try {
                      this.formInfo["fieldGroups"][key]["fieldGroups"][key2][
                        "fields"
                      ][key3]["input"] =
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fields"
                        ][key3]["input"];
                    } catch (err) {
                      console.log("error during assignment:", err);
                      console.log(
                        "this.formInfo['fieldGroups'][" +
                          key +
                          "]['fieldGroups'][" +
                          key2 +
                          "]['fields'][" +
                          key3 +
                          "][input]: " +
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                      );
                    }
                  } else if (data3.type == "boolean") {
                    try {
                      this.formInfo["fieldGroups"][key]["fieldGroups"][key2][
                        "fields"
                      ][key3]["input"] =
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fields"
                        ][key3]["input"];
                    } catch (err) {
                      console.log("error during assignment:", err);
                      console.log(
                        "this.formInfo['fieldGroups'][" +
                          key +
                          "]['fieldGroups'][" +
                          key2 +
                          "]['fields'][" +
                          key3 +
                          "][input]: " +
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                      );
                    }
                  } else if (data3.type == "number") {
                    try {
                      this.formInfo["fieldGroups"][key]["fieldGroups"][key2][
                        "fields"
                      ][key3]["input"] = parseInt(
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fields"
                        ][key3]["input"]
                      );
                    } catch (err) {
                      console.log("error during assignment:", err);
                      console.log(
                        "this.formInfo['fieldGroups'][" +
                          key +
                          "]['fieldGroups'][" +
                          key2 +
                          "]['fields'][" +
                          key3 +
                          "][input]: " +
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                      );
                    }
                  } else if (data3.type == "currency") {
                    try {
                      console.log(
                        "currency in filled data",
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fields"
                        ][key3]["input"]
                      );
                      this.formInfo["fieldGroups"][key]["fieldGroups"][key2][
                        "fields"
                      ][key3]["input"] = new Intl.NumberFormat("en-IN").format(
                        parseInt(
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                        )
                      );
                    } catch (err) {
                      console.log("error during assignment:", err);
                      console.log(
                        "this.formInfo['fieldGroups'][" +
                          key +
                          "]['fieldGroups'][" +
                          key2 +
                          "]['fields'][" +
                          key3 +
                          "][input]: " +
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                      );
                    }
                  } else {
                    try {
                      this.formInfo["fieldGroups"][key]["fieldGroups"][key2][
                        "fields"
                      ][key3]["input"] =
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fields"
                        ][key3]["input"];
                    } catch (err) {
                      console.log("error during assignment:", err);
                      console.log(
                        "this.formInfo['fieldGroups'][" +
                          key +
                          "]['fieldGroups'][" +
                          key2 +
                          "]['fields'][" +
                          key3 +
                          "][input]: " +
                          filledData["fieldGroups"][key]["fieldGroups"][key2][
                            "fields"
                          ][key3]["input"]
                      );
                    }
                  }
                }

                // 333333
                // fieldGroups.fieldGroups
                if (data2.fieldGroups) {
                  for (var key3 in data2.fieldGroups) {
                    let data3 =
                      filledData["fieldGroups"][key]["fieldGroups"][key2][
                        "fieldGroups"
                      ][key3];
                    for (var key4 in data3.fields) {
                      let data4 =
                        filledData["fieldGroups"][key]["fieldGroups"][key2][
                          "fieldGroups"
                        ][key3]["fields"][key4];
                      if (data4.type == "multi-select") {
                        try {
                          this.formInfo["fieldGroups"][key]["fieldGroups"][
                            key2
                          ]["fieldGroups"][key3]["fields"][key4]["input"] =
                            filledData["fieldGroups"][key]["fieldGroups"][key2][
                              "fieldGroups"
                            ][key3]["fields"][key4]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          console.log(
                            "this.formInfo['fieldGroups'][" +
                              key +
                              "]['fieldGroups'][" +
                              key2 +
                              "]['fields'][" +
                              key3 +
                              "]['fields'][" +
                              key4 +
                              "][input]" +
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                          );
                        }
                      } else if (data4.type == "boolean") {
                        try {
                          this.formInfo["fieldGroups"][key]["fieldGroups"][
                            key2
                          ]["fieldGroups"][key3]["fields"][key4]["input"] =
                            filledData["fieldGroups"][key]["fieldGroups"][key2][
                              "fieldGroups"
                            ][key3]["fields"][key4]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          console.log(
                            "this.formInfo['fieldGroups'][" +
                              key +
                              "]['fieldGroups'][" +
                              key2 +
                              "]['fields'][" +
                              key3 +
                              "]['fields'][" +
                              key4 +
                              "][input]" +
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                          );
                        }
                      } else if (data4.type == "number") {
                        try {
                          this.formInfo["fieldGroups"][key]["fieldGroups"][
                            key2
                          ]["fieldGroups"][key3]["fields"][key4]["input"] =
                            parseInt(
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                            );
                        } catch (err) {
                          console.log("error during assignment:", err);
                          console.log(
                            "this.formInfo['fieldGroups'][" +
                              key +
                              "]['fieldGroups'][" +
                              key2 +
                              "]['fields'][" +
                              key3 +
                              "]['fields'][" +
                              key4 +
                              "][input]" +
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                          );
                        }
                      } else if (data4.type == "currency") {
                        try {
                          console.log(
                            "inside currency filled data",
                            filledData["fieldGroups"][key]["fieldGroups"][key2][
                              "fieldGroups"
                            ][key3]["fields"][key4]["input"]
                          );
                          this.formInfo["fieldGroups"][key]["fieldGroups"][
                            key2
                          ]["fieldGroups"][key3]["fields"][key4]["input"] =
                            new Intl.NumberFormat("en-IN").format(
                              parseInt(
                                filledData["fieldGroups"][key]["fieldGroups"][
                                  key2
                                ]["fieldGroups"][key3]["fields"][key4]["input"]
                              )
                            );
                        } catch (err) {
                          console.log("error during assignment:", err);
                          console.log(
                            "this.formInfo['fieldGroups'][" +
                              key +
                              "]['fieldGroups'][" +
                              key2 +
                              "]['fields'][" +
                              key3 +
                              "]['fields'][" +
                              key4 +
                              "][input]" +
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                          );
                        }
                      } else {
                        try {
                          this.formInfo["fieldGroups"][key]["fieldGroups"][
                            key2
                          ]["fieldGroups"][key3]["fields"][key4]["input"] =
                            filledData["fieldGroups"][key]["fieldGroups"][key2][
                              "fieldGroups"
                            ][key3]["fields"][key4]["input"];
                        } catch (err) {
                          console.log("error during assignment:", err);
                          console.log(
                            "this.formInfo['fieldGroups'][" +
                              key +
                              "]['fieldGroups'][" +
                              key2 +
                              "]['fields'][" +
                              key3 +
                              "]['fields'][" +
                              key4 +
                              "][input]" +
                              filledData["fieldGroups"][key]["fieldGroups"][
                                key2
                              ]["fieldGroups"][key3]["fields"][key4]["input"]
                          );
                        }

                        //this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                      }
                    }
                  }
                }
                // 333333
              }
            }

            //}
          }

          if (filledDataTab) {
            //console.log('inside filled data tab', filledDataTab);
            setTimeout(function () {
              //self.activeTabAfterReload(filledDataTab);
              self.activeTab(filledDataTab);
              if(self.$route.query.process){
                self.activeTab(self.$route.query.process);
              }else{
                return;
              }
            }, 1000);
          }
        } else {
          // Removing previous data
          localStorage.removeItem("filledData");
          localStorage.removeItem("filledDataTab");
          sessionStorage.removeItem("filledData");
          sessionStorage.removeItem("filledDataTab");
        }
      }
      // End setting previously filled data

      // Setting traceinfo and distributor details section

      if (this.formInfo.fieldGroups.distributor.fields.distributorID) {
        this.formInfo.fieldGroups.distributor.fields.distributorID.input =
          this.distributorID;
      }
      //console.log(" this.formInfo.fieldGroups.distributor.fields.agentCode.input", this.formInfo.fieldGroups.distributor.fields.agentCode.input)
      if (this.formInfo.fieldGroups.distributor.fields.agentCode) {
        this.formInfo.fieldGroups.distributor.fields.agentCode.input =
          this.agentCode;
      }

      // End mofification
    },
    getDateFormat(dt) {
      if(dt) {
        let arr = dt.split("-");
        if(arr[2].length > 2) {
          return arr['2']+'-'+arr['1']+'-'+arr['0'];
        } else {
          return dt;
        }
      } else {
        return "";
      }
    },
    getPincodeDetails(pincode) {
      this.loadingState = true;
      this.loadingMsg="Fetching Pincode Details"
      //console.log('getPincodeDetails called: ', pincode);
      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      let reqData = [pincode];
      //this.loading = true;
      this.backendApi("getPincodeInfo", reqData, headers, "get")
        .then((response) => {
          this.loadingState = false;
          if (response == "error") {
            //this.apiError = true;
            console.log("pincode api error: block");
          } else if (response && response.data) {
            //console.log('pincode response: ', response.data.data);
            let v = response?.data?.data[0];
            sessionStorage.setItem("pincode_data", JSON.stringify(v));
            // Setting Pincode Data
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input =
              pincode;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.cityDistrict.input =
              v?.city;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input =
              v?.state;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.nationality.input =
              v?.country;
          }
        })
        .catch((error) => {
          this.loadingState=false;
          console.log("error msg block getting pincode info", error);
          //this.loading = false;
          //this.apiError = true;
        });
    },
    getPincodeDetails3(pincode) {
      this.loadingState = true;
      this.loadingMsg="Fetching Pincode Details"
      //console.log('getPincodeDetails called: ', pincode);
      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };
      let reqData = [pincode];
      //this.loading = true;
      this.backendApi("getPincodeInfo", reqData, headers, "get")
        .then((response) => {
          this.loadingState = false;
          //this.loading = false;
          if (response == "error") {
            //this.apiError = true;
            console.log("pincode api error: block");
          } else if (response && response.data) {
            //console.log('pincode response: ', response.data.data);
            let v = response?.data?.data[0];
            sessionStorage.setItem("pincode_data", JSON.stringify(v));
            // Setting Pincode Data
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.zipCode.input =
              pincode;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.cityDistrict.input =
              v?.city;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.state.input =
              v?.state;
            this.formInfo.fieldGroups.proposerDetails.fieldGroups.permanentAddressDetails.fields.nationality.input =
              v?.country;
          }
        })
        .catch((error) => {
          this.loadingState=false;
          console.log("error msg block getting pincode info", error);
          //this.loading = false;
          //this.apiError = true;
        });
    },
    checkVisibility(value) {
      //console.log("check Visibility called: ", value);
      // default expression evalutiona added along with visibility
      if (value) {
        let obj = this.getFinalObject(value);
        try {
          console.log('eval: ', eval(obj));
          return eval(obj);
        } catch (err) {
          console.log("error visibility exp: ", value);
        }
      } else {
        return true;
      }
    },
    activePage(val) {
      if (val == "proposerDetails") {
        return "active";
      } else {
        return "disabled";
        //return "";
      }
    },
    activeNextTab(currentTab) {
        console.log("active next tab called", this.tabsArr);
        let ckk = this.checkVisibility(this.formInfo['fieldGroups'][currentTab]['visibility']);
        if(ckk == true) {
          // Setting Up Filled Tab on localstorage
          let filledDataTab=""
          if(sessionStorage.getItem("filledDataTab"))
          {
            filledDataTab = sessionStorage.getItem("filledDataTab")
          }
          if(this.tabsArr.indexOf(currentTab) ==0 && !filledDataTab)
          {
            localStorage.setItem("filledDataTab", JSON.stringify(currentTab));
            sessionStorage.setItem("filledDataTab", JSON.stringify(currentTab));
          }
          else if(this.tabsArr.indexOf(currentTab)>=this.tabsArr.indexOf(filledDataTab))
          {
            localStorage.setItem("filledDataTab", JSON.stringify(currentTab));
            sessionStorage.setItem("filledDataTab", JSON.stringify(currentTab));
          }
       
          localStorage.setItem("filledData", JSON.stringify(this.formInfo));
          sessionStorage.setItem("filledData", JSON.stringify(this.formInfo)); 
        }
  
        var tabIndex = this.tabsArr.indexOf(currentTab);
        let nextTab;
        //console.log('tab index: ', tabIndex)
        if (tabIndex < this.tabsArr.length - 1) {
          nextTab = this.tabsArr[tabIndex + 1];
          console.log('next tab: ', nextTab);
        } else {
          // If conntrol is on last tab
          console.log("inside: conntrol is on last tab");
          this.$router.push('/confirm-payment')
          // this.$router.push('/confirm-payment').then(() => {
          //     setTimeout(() => {
          //       window.location.reload();
          //     }, 100)
          //   }).catch(err => {
          //     console.error('Navigation error:', err);
          //   });
        }
        if (nextTab) {
          this.activeTab(nextTab);
        }
      },
      activeTabAfterReload(filledDataTab)
      {
        let tabIndex=this.tabsArr.indexOf(filledDataTab)
        for(let i=0;i<=tabIndex;i++)
        {
          let tab=this.tabsArr[i];
          if ($("#proposalId_" + tab).hasClass("show active")) {
            $("#proposalId_" + tab).removeClass("show active");
          }
  
          if ($("#tablink_" + tab).hasClass("disabled")) {
            $("#tablink_" + tab).removeClass("disabled");
            $("#tablinka_" + tab).removeClass("disabled");
          }
          $("#tablink_" + tab).addClass('active');
          $("#tablinka_" + tab).addClass('active');
          $("#tablinka_" + tab).attr("aria-selected", "true");
        }
        $("#proposalId_" + filledDataTab).addClass('show active');
      },
      activeTab(tabToActive) {
        console.log("activeTab Called", tabToActive);
  
        // Scroll Top
        this.scrollToTop();
        let visibilityExp = "";
        if(tabToActive == 'insuredMembers') {
          visibilityExp = this.formInfo["fieldGroups"][tabToActive]['0']["visibility"];
        } else {
          visibilityExp = this.formInfo["fieldGroups"][tabToActive]["visibility"];
        }
     
        let ckk = this.checkVisibility(visibilityExp);
        if (ckk == true) {
          //console.log('this.tabsArr', this.tabsArr)
          this.currentTab = tabToActive;
          sessionStorage.setItem("currentTab", JSON.stringify(this.currentTab));
  
          for (var i = 0; i < this.tabsArr.length; i++) {
            var tab = this.tabsArr[i];
            if ($("#step" + tab + "-tab").hasClass("active")) {
              console.log("inside tab active");
              $("#step" + tab + "-tab").removeClass("active");
              $("#step" + tab + "-tab").attr("aria-selected", "false");
              $("#step" + tab + '-tab').addClass("disabled");
              $("#step" + tab).removeClass("show active");
            }
          }
          if ($("#step" + tabToActive + "-tab").hasClass("disabled")) {
            $("#step" + tabToActive + "-tab").removeClass("disabled");
          }
          $("#step" + tabToActive + "-tab").addClass("active");
          $("#step" + tabToActive).addClass("show active");
          $("#step" + tabToActive + "-tab").attr("aria-selected", "true");
        } else {
          this.activeNextTab(tabToActive);
        }
      },
      goBack() {
        this.currentTab = JSON.parse(sessionStorage.getItem("currentTab"));
        console.log("current tab: ", this.currentTab);
        var tabIndex = this.tabsArr.indexOf(this.currentTab);
        this.activePrevTab(this.currentTab);
      },
      activePrevTab(tabToActive) {
        console.log("active prev tab called", this.tabsArr);
        var tabIndex = this.tabsArr.indexOf(tabToActive);
        if (tabIndex > 0) {
          console.log("inside prev tab true");
          if(tabToActive != 'traceInfo' && tabToActive != 'distributor') {
            let prevTab = this.tabsArr[tabIndex - 1];
            //console.log('uuu: ', this.formInfo['fieldGroups'][prevTab]['visibility']);
            let ckk = this.checkVisibility(
              this.formInfo["fieldGroups"][prevTab]["visibility"]
            );
            if (ckk == true) {
              this.activeTab(prevTab);
            } else {
              this.activePrevTab(prevTab);
            }
          }
        } else {
          this.$router.push("/addon-rider");
        }
      },
    isTabContentActive(val) {
      if (val == "proposerDetails") {
      return "show active";
      } else {
      // return "disabled";
      return "";
      }
    },
    getColSize(val, ind1, ind2, ind3 = "") {
      //console.log('get col size info, Val: ', val, 'Ind1: ', ind1, 'Ind2', ind2, 'Ind3: ', ind3)
      if (ind1 == "healthQuestions") {
        return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 member-option";
      } else if (ind1 == "nomineeInfo") {
        if (ind2 == "address" || ind3 == "appointeeAddress") {
          return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3";
        } else {
          return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 column";
        }
      } else if (val == "boolean") {
        return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3";
      } else {
        return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-3";
      }
    },
    getAutoCompleteData() {
      return getIsAutoComplete();
    },
    scrollToTop() {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
      },
      checkVisibility2(value, sectionIndex) {
        //console.log("check Visibility called: ", value);
        // default expression evalutiona added along with visibility
  
        
  
        if (value) {
          if(sectionIndex == 'healthQuestions') {
            let tmpValue = this.getVisitibilityExpIndexZero(value);
            value = tmpValue;
          } 
  
          let obj = this.getFinalObject(value);
          try {
            //console.log('iioioioioioiio: ', eval(obj));
            return eval(obj);
          } catch (err) {
            //console.log("error visibility exp2: ", value);
          }
        } else {
          return true;
        }
      },
    saveProposal(sectionIndex) {  
        this.loadingState = true;
        this.loadingMsg="Saving Proposal..."
  
        // if(this.selectedPlan?.trackInfo?.manufacturerQuoteID) {
        //   this.formInfo.fieldGroups.traceInfo.fields["manufacturerQuoteID"].input = this.selectedPlan?.trackInfo?.manufacturerQuoteID;
        // }
        let propoalData = {
          "journey_id": JSON.parse(sessionStorage.getItem("journey_id")),
          "tag_name" : sectionIndex,
          "proposal_json": this.proposalJson,
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
          "Content-Type": "application/json",
        };
        //console.log('qData: ', qData);
        this.backendApi("saveProposal", propoalData, headers)
          .then((response) => {
            this.loadingState = false;
            this.activeNextTab(sectionIndex);
            if (response == 'error') {
              self.apiError = true;
            }
            else if (response && response.data) {
              // let currTab = JSON.parse(sessionStorage.getItem('filledDataTab'))
              // if('fieldGroups' in response.data.data.proposal_json.fieldGroups[currTab] && Object.keys(response.data.data.proposal_json.fieldGroups[currTab].fieldGroups).length != 0){
                // sessionStorage.setItem(currTab, JSON.stringify(response.data.data.proposal_json.fieldGroups[currTab].fieldGroups))
              // }else{
                // sessionStorage.setItem(currTab, JSON.stringify(response.data.data.proposal_json.fieldGroups[currTab].fields))
              // }
            }
          })
          .catch((error) => {
            this.loadingState=false;
            console.log('error msg block dp', error);
            // let msg=['We are facing some issue from backend while saving proposal data ']
  
            //   this.$router.push({ path: '/alert', query: {backUrl:'proposal-page', msg: msg }});
          });
      },
      checkValidation(value, label = "") {
        //console.log('check Validation called: ', value);
        if (value) {
          let obj = this.getFinalObject(value);
          try {
            return eval(obj);
          } catch (err) {
            console.log("check validation error exp 2: ", value);
            return true;
          }
        } else {
          //return false;
        }
      },
      checkValidation2(value, label = "") {
        //console.log('check Validation 2 called: ', value);
        if (value) {
          let obj = this.getFinalObject(value);
          console.log('Validation 2 obj: ', obj);
          try {
            return eval(obj);
          } catch (err) {
            console.log("check validation2 error exp 2: ", obj);
            return true;
          }
        } else {
          //return false;
        }
      },
      checkPattern(value, pattern) {
        try {
          return eval(eval(pattern).test(value));
        } catch {
          console.log("Invalid regular expression: ", pattern);
          return true;
        }
      },
      saveProposalDetails(reqData) {
        this.loadingState = true;
      this.loadingMsg="Saving Proposal..."
        var headers = {
          "quoteId": this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("saveProposalDetailsData", reqData, headers)
          .then((response) => {
            this.loadingState = false;
            //console.log('save quote response: ', response);
            if (response == 'error') {
              //self.apiError = true;
            } else if (response && response.data) {
              console.log('proposal details data saved');
            }
          })
          .catch((error) => {
            this.loadingState=false;
            console.log('error msg block proposal details data', error);
          });
      },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/process-wizard.scss";
</style>
