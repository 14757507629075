import { createRouter, createWebHashHistory } from 'vue-router'
import landing from '../views/landingPage.vue'
import NewJourney from '../views/newJourney.vue'
import FormWizard from '@/views/formWizard.vue'
import MultipleQuotes from "@/views/multipleQuotes.vue"
import ProcessWizard from "@/views/processWizard.vue"
import SingleQuote from '@/views/SingleQuote.vue'
import confirmPay from "@/views/confirmPay.vue"
import confirmPayDiy from "@/views/confirmPayDiy.vue"
import VehicleQuote from '@/views/vehicleQuote.vue'
import CompleteThankyou from '@/views/rmThankYou.vue'
import KycForm from "@/views/kycForm.vue"
import OvdsUpload from '@/views/OvdsUpload.vue'
import kycSuccess from '@/views/kycSuccess.vue'
import KycFailed from '@/views/KycFailed.vue'
import errorPage from '@/views/errorPage.vue'
import dashboard from '@/views/dashboard.vue'
import PaymentSuccess from '@/views/PaymentSuccess.vue'
import inspection from '@/views/inspection.vue'
import paymentPending from '@/views/pending.vue'
import FileUploadView from '../views/FileUploadView.vue'
import PaymentFailed from '../views/paymentFail.vue'

const routes = [
    {
      path: '/',
      name: 'Landing-page',
      component: landing,
    },
    {
      path:"/payment",
      name:"PaymentSuccess",
      component:PaymentSuccess
    },
    {
      path:"/inspection",
      name:"inspection",
      component:inspection
    },
    {
      path:"/pending",
      name:"paymentPending",
      component:paymentPending
    },
    {
      path: '/New-Journey',
      name: 'New-Journey',
      component: NewJourney,
    },
    {
      path: '/form-Wizard',
      name: 'fromWizard',
      component: FormWizard,
    }, 
   
    {
      path: '/policy-quote',
      name: 'MultipleQuotes',
      component: MultipleQuotes,
    }, 
    {
      path:"/process-wizard",
      name:"ProcessWizard",
      component: ProcessWizard,
    },
    {
      path:"/vehicle-quote",
      name:"VehicleQuote",
      component:VehicleQuote
    },
    {
      path:"/policy-details",
      name:"singleQuote",
      component: SingleQuote,
     },
     {
      path:"/dashboard",
      name:"dashboard",
      component: dashboard,
     },
    {
      path:"/error-page",
      name:"errorPage",
      component: errorPage,
    },
    {
      path:"/confirm-payment",
      name:"confirmPay",
      component:confirmPay
    },
    {
      path:"/confirm-payment-diy",
      name:"confirmPayDiy",
      component:confirmPayDiy
    },
    {
      path:"/Thank-you-RM",
      name:"completeThankyou",
      component:CompleteThankyou
    },
    {
      path:"/ckyc-form",
      name:"KycForm",
      component:KycForm
    },
    {
      path:"/upload-ovds",
      name:"OvdsUpload",
      component:OvdsUpload
    },
    {
      path:"/kyc-success",
      name:"kycSuccess",
      component:kycSuccess
    },
    {
      path:"/kyc-Failed",
      name:"KycFailed",
      component:KycFailed
    },
    {
      path: '/fileUpload',
      name: 'file-upload',
      component: FileUploadView,
    },
    {
      path:"/paymentfailed",
      name:"PaymentFailed",
      component:PaymentFailed
    },
    

]  

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // scrollBehavior(to, from, savedPosition) { 
    //   // always scroll to top
    //   return { top: 0 }
    // }
  })
  export default router